<template>
	<section>
		<Card v-for="(v, i) in [5, 4, 3, 2, 1]" :key="i" class="mb-5">
				<v-skeleton-loader class="d-flex flex-wrap align-center ga-3 px-6 py-4">
					<span
						class="avatar mr-3 v-skeleton-loader__bone"
						:style="{
							'background-color': `rgba(153, 153, 153, 0.${v})`,
						}"></span>
					<span
						class="paragraph v-skeleton-loader__bone"
						:style="{
							width: v % 2 !== 0 ? '191px' : '143px',
							'background-color': `rgba(153, 153, 153, 0.${v})`,
						}"></span>
				</v-skeleton-loader>
		</Card>
	</section>
</template>

<script>
	export default {
		name: "HistoricList",
	};
</script>

<style scoped>
	.paragraph {
		height: 20px;
		border-radius: 5px;
	}
	.avatar {
		width: 40px;
		height: 40px;
		border-radius: 50%;
	}
</style>
