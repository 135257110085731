<template>
  <v-data-table
    hide-default-header
    hide-default-footer
    :headers="headers"
    :items="dataColumns"
    class="table-detail fixed-header-table">
    
    <template v-for="(header, index) in headers" v-slot:[`item.${header.value}`]="{ item }">
      <v-skeleton-loader :key="index" class="d-flex flex-column text-card-loader">
        <span class="v-skeleton-loader__bone"
          :style="{ backgroundColor: item.color }"
          :class="item.index % 2 === 0 ? 'pair-text' : 'odd-text'">
        </span>
      </v-skeleton-loader> 
    </template>
  </v-data-table>
</template>

<script>
	export default {
		name: "DataTableSkeleton",
    props: {
      columnsNumber: { type: Number, default: 2 },
    },
    computed: {
      headers() {
        return Array.from({ length: this.columnsNumber }, (_, i) => ({ text: String(i + 1), value: String(i + 1) }));
      },
      dataColumns() {
        const colors = ["#99999980", "#99999966", "#9999994D", "#99999933", "#9999991A"];
        return Array.from({ length: 5 }, (_, i) => {
          const item = { color: colors[i], index: i };
          for (let j = 0; j < this.columnsNumber; j++) {
            item[j + 1] = i;
          }
          return item;
        });
      },
    },
	};
</script>

<style lang="scss" scoped>
	.v-skeleton-loader__bone {
		border-radius: 6px;
    height: 20px;
	}
  .odd-text {
    width: 143px;
    background-color: #9999997e;
  }
  .pair-text {
    width: 191px;
    background-color: #9999994d;

  }
	.col {
    padding: 0px;
  }
  .table-detail {
    border: 1px solid var(--borderGray);
    border-radius: 10px;
    max-height: calc(100vh - 426px) !important;
    overflow: auto;
    ::v-deep th[role=columnheader] {
      text-wrap: nowrap;
    }
  }
  table {
    color: var(--fontColor) !important ;
  }
  ::v-deep {
    tr {
      color: var(--fontColor);
    }
  }
  .fixed-header-table {
    display: flex; 
  }

  ::v-deep .v-data-table__wrapper{
    width: 100%;
  }
</style>