import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '../i18n/en.js';
import es from '../i18n/es.js';

Vue.use(VueI18n);

const messages = { en, es };

let locale = navigator.language || navigator.userLanguage;

if (locale.indexOf('-') > -1) {
  locale = locale.substring(0, locale.indexOf('-'));
}

export default new VueI18n({
  locale, // set locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
});
