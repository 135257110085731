<template>
  <div class="containerDateDecimalForm">
    <Form
      class="dateDecimalForm"
      :fields="fieldsDateDecimal"
      @updatedForm="
        getStatusComponent = false;
        update($event);
      "
      :globalValues="globalValues"
      :getStatus="getStatusComponent"
      :currentDataValues="currentDataValues"
      :submitForm="submitFormComponent"
      @formStatus="formSubmitted"
      :resetForm="resetForm"
      :returnAlerts="true"
      primaryColor="var(--secondary)"
    />
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";

export default {
  name: "DateDecimalForm",
  data: () => ({
    getStatusComponent: false,
    dirty: false,
  }),
  props: {
    globalValues: { type: Array },
    currentDataValues: { type: Object },
    submitFormComponent: { type: Boolean },
    resetForm: { type: Boolean },
    dataSource: { type: Object },
    getStatus: { type: Boolean },
  },
   watch: {
    submitFormComponent(v) {
      this.getStatusComponent = v;
      this.$emit("dirty", this.dirty);
    },
    getStatus(v) {
      this.getStatusComponent = v;
      this.$emit("dirty", this.dirty);
    }
   },
  methods: {
    formSubmitted(event) {
      if (this.dirty !== event.dirty && event.dirty) {
        this.dirty = event.dirty;
        this.$emit("dirty", event.dirty);
      }
      this.getStatusComponent = false;
    },
    update(event) {
      let data = {};
      const { datePattern, decimalSeparator } = event || {}
      if (decimalSeparator) data.decimalSeparator = decimalSeparator;
      else if (datePattern) data.datePattern = datePattern;
      else data = null;
      this.$emit("isValidForm", {valid: !(datePattern && decimalSeparator)});
      this.$emit("update", data);
    },
  },
  computed: {
    fieldsDateDecimal() {
      let fields = [
        {
          name: "datePattern",
          label: i18n.t("datePattern"),
          fieldType: "selectBox",
          dataSource: this.dataSource.dateFormat,
          validators: {
            notAvailableFieldsSetTogether: {
              field: "decimalSeparator",
              msg: i18n.t("onlyAvailableOneOfValues"),
            },
          },
          info: {
            color: "var(--primary)",
            text: i18n.t("datePatternTooltipOutput"),
            maxWidth: 200,
          },
        },
        {
          name: "decimalSeparator",
          label: i18n.t("decimalSeparator"),
          fieldType: "selectBox",
          dataSource: this.dataSource.defaultDecimalSeparator,
          validators: {
            notAvailableFieldsSetTogether: {
              field: "datePattern",
              msg: i18n.t("onlyAvailableOneOfValues"),
            },
          },
          info: {
            color: "var(--primary)",
            text: i18n.t("decimalSeparatorTooltipOutput"),
            maxWidth: 200,
          },
        },
      ];
      return fields;
    },
  },
};
</script>

<style lang="scss" scoped>
.containerDateDecimalForm {
  .dateDecimalForm {
    padding-bottom: 10px;
  }
  ::v-deep .row >div {
    margin-top: 12px;
  }
}
</style>
