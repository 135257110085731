import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userLogged: undefined,
    userGroups: undefined,
    actualGroup: undefined,
    httpStatus: undefined,
    dataView: undefined,
    extraDataView: undefined,
    actualView: undefined,
    appMenu: undefined,
    menuOpen: undefined,
    loadingPage: 0,
    loadingData: false,
    envTheme: undefined,
    swUpdateExists: false,
    itemToAddEdit: undefined,
    itemToAddEditStepper: undefined,
    itemToImport: undefined,
    dictionaryToImport: undefined,
    itemToConfirmAction: undefined,
    isExecuteProcessModalOpen: false,
    showSnackBar: undefined,
    headerAndBreadcrumsHeight: undefined,
    processData: undefined,
    inputDefinitionsData: undefined,
    outputDefinitionsData: undefined,
    dictionariesData: undefined,
    listsData: undefined,
    volatileData: undefined,
    unsubscribe: {
      InputDefinitions: undefined,
      OutputDefinitions: undefined,
      Dictionaries: undefined,
      Lists: undefined,
      ProcessHistory: undefined,
      userLogged: undefined,
      Detail: undefined,
      DetailDictionary: undefined,
    },
    itemToSelect: undefined,
    routeVuex:undefined,
    statusProgress: undefined
  },
  mutations: {
    SET_USER_LOGGED(state, userLogged) {
      state.userLogged = userLogged;
    },
    SET_USER_GROUPS(state, userGroups) {
      state.userGroups = userGroups;
    },
    SET_ACTUAL_GROUP(state, actualGroup) {
      state.actualGroup = actualGroup;
    },
    SET_DATA_VIEW(state, dataView) {
      state.dataView = dataView;
    },
    SET_EXTRA_DATA_VIEW(state, extradataView) {
      if (extradataView) {
        if (!state.extraDataView) state.extraDataView = {};

        Object.keys(extradataView).forEach((key) => {
          Vue.set(state.extraDataView, key, extradataView[key]);
        });
      } else {
        state.extraDataView = extradataView;
      }
    },
    SET_ACTUAL_VIEW(state, actualView) {
      state.actualView = actualView;
    },
    SET_APP_MENU(state, appMenu) {
      state.appMenu = appMenu;
    },
    SET_MENU_OPEN(state, menuOpen) {
      state.menuOpen = menuOpen;
    },
    SET_LOADING_PAGE(state, loadingPage) {
      let result = state.loadingPage;
      if(loadingPage) ++result;
      else --result;
      state.loadingPage = Math.max(0, result);
    },
    SET_LOADING_DATA(state, loadingData) {
      state.loadingData = loadingData;
    },
    SET_SETS_STATUS(state, setsStatus) {
      state.setsStatus = setsStatus;
    },
    SET_ENV_THEME(state, envTheme) {
      state.envTheme = envTheme;
    },
    SET_SW_UPDATE_EXISTS(state, swUpdateExists) {
      state.swUpdateExists = swUpdateExists;
    },
    SET_ITEM_TO_ADD_EDIT(state, itemToAddEdit) {
      state.itemToAddEdit = itemToAddEdit;
    },
    SET_ITEM_TO_ADD_EDIT_STEPPER(state, itemToAddEditStepper) {
      state.itemToAddEditStepper = itemToAddEditStepper;
    },
    SET_ITEM_TO_IMPORT(state, itemToImport) {
      state.itemToImport = itemToImport;
    },
    SET_ITEM_TO_CONFIRM_ACTION(state, itemToConfirmAction) {
      state.itemToConfirmAction = itemToConfirmAction;
    },
    SET_IS_UPLOAD_FILE_MODAL_OPEN(state, isExecuteProcessModalOpen) {
      state.isExecuteProcessModalOpen = isExecuteProcessModalOpen;
    },
    SET_SHOW_SNACKBAR(state, showSnackBar) {
      state.showSnackBar = showSnackBar;
    },
    SET_HEADER_AND_BREADCRUMS_HEIGHT(state, headerAndBreadcrumsHeight) {
      state.headerAndBreadcrumsHeight = headerAndBreadcrumsHeight;
    },
    SET_PROCESS_DATA(state, processData) {
      state.processData = processData;
    },

    SET_INPUT_DEFINITIONS_DATA(state, inputDefinitionsData) {
      state.inputDefinitionsData = inputDefinitionsData;
    },
    SET_OUTPUT_DEFINITIONS_DATA(state, outputDefinitionsData) {
      state.outputDefinitionsData = outputDefinitionsData;
    },
    SET_DICTIONARIES_DATA(state, dictionariesData) {
      state.dictionariesData = dictionariesData;
    },
    SET_LISTS_DATA(state, listsData) {
      state.listsData = listsData;
    },
    SET_VOLATILE_DATA(state, volatileData) {
      state.volatileData = volatileData;
    },
    SET_UNSUBSCRIBE(state, unsubscribe) {
      state.unsubscribe = { ...state.unsubscribe, ...unsubscribe };
    },
    SET_HTTP_STATUS(state, httpStatus) {
      state.httpStatus = httpStatus;
    },
    SET_ITEM_TO_SELECT(state, itemToSelect) {
      state.itemToSelect = itemToSelect;
    },
    SET_ROUTE_VUEX(state, routeVuex) {
      state.routeVuex = routeVuex;
    },
    SET_ITEM_TO_IMPORT_DICTIONARY(state, dictionaryToImport) {
      state.dictionaryToImport = dictionaryToImport;
    },
    SET_STATUS_PROGRESS_PROCESS(state, statusProgress) {
      state.statusProgress = statusProgress;
    }
  },
  actions: {
    setUserLogged({ commit }, userLogged) {
      commit("SET_USER_LOGGED", userLogged);
    },
    setUserGroups({ commit }, userGroups) {
      commit("SET_USER_GROUPS", userGroups);
    },
    setActualGroup({ commit }, actualGroup) {
      commit("SET_ACTUAL_GROUP", actualGroup);
    },
    setDataView({ commit }, dataView) {
      commit("SET_DATA_VIEW", dataView);
    },
    setExtraDataView({ commit }, extraDataView) {
      commit("SET_EXTRA_DATA_VIEW", extraDataView);
    },
    setActualView({ commit }, actualView) {
      if (actualView) {
        commit("SET_ACTUAL_VIEW", actualView);
      }
    },
    setAppMenu({ commit }, appMenu) {
      commit("SET_APP_MENU", appMenu);
    },
    setMenuOpen({ commit }, menuOpen) {
      commit("SET_MENU_OPEN", menuOpen);
    },
    setLoadingPage({ commit }, loadingPage) {
      commit("SET_LOADING_PAGE", loadingPage);
    },
    setLoadingData({ commit }, loadingData) {
      commit("SET_LOADING_DATA", loadingData);
    },
    setEnvTheme({ commit }, envTheme) {
      commit("SET_ENV_THEME", envTheme);
    },
    setSwUpdateExists({ commit }, swUpdateExists) {
      commit("SET_SW_UPDATE_EXISTS", swUpdateExists);
    },
    setItemToAddEdit({ commit }, itemToAddEdit) {
      commit("SET_ITEM_TO_ADD_EDIT", itemToAddEdit);
    },
    setItemToImport({ commit }, itemToImport) {
      commit("SET_ITEM_TO_IMPORT", itemToImport);
    },
    setItemToAddEditStepper({ commit }, itemToAddEditStepper) {
      commit("SET_ITEM_TO_ADD_EDIT_STEPPER", itemToAddEditStepper);
    },
    setItemToConfirmAction({ commit }, itemToConfirmAction) {
      commit("SET_ITEM_TO_CONFIRM_ACTION", itemToConfirmAction);
    },
    setIsExecuteProcessModalOpen({ commit }, isExecuteProcessModalOpen) {
      commit("SET_IS_UPLOAD_FILE_MODAL_OPEN", isExecuteProcessModalOpen);
    },
    setShowSnackBar({ commit }, showSnackBar) {
      commit("SET_SHOW_SNACKBAR", showSnackBar);
    },
    setHeaderAndBreadcrumsHeight({ commit }, headerAndBreadcrumsHeight) {
      commit("SET_HEADER_AND_BREADCRUMS_HEIGHT", headerAndBreadcrumsHeight);
    },
    setProcessData({ commit }, processData) {
      commit("SET_PROCESS_DATA", processData);
    },
    setInputDefinitionsData({ commit }, inputDefinitionsData) {
      commit("SET_INPUT_DEFINITIONS_DATA", inputDefinitionsData);
    },
    setOutputDefinitionsData({ commit }, outputDefinitionsData) {
      commit("SET_OUTPUT_DEFINITIONS_DATA", outputDefinitionsData);
    },
    setDictionariesData({ commit }, dictionariesData) {
      commit("SET_DICTIONARIES_DATA", dictionariesData);
    },
    setListsData({ commit }, listsData) {
      commit("SET_LISTS_DATA", listsData);
    },
    setVolatileData({ commit }, volatileData) {
      commit("SET_VOLATILE_DATA", volatileData);
    },
    setUnsubscribe({ commit }, unsubscribe) {
      commit("SET_UNSUBSCRIBE", unsubscribe);
    },
    setHttpStatus({ commit }, httpStatus) {
      commit("SET_HTTP_STATUS", httpStatus);
    },
    setItemToSelect({ commit }, itemToSelect) {
      commit("SET_ITEM_TO_SELECT", itemToSelect);
    },
    setRouteVuex({ commit }, routeVuex) {
      commit("SET_ROUTE_VUEX", routeVuex);
    },
    setItemToImportDictionary({ commit }, dictionaryToImport) {
      commit("SET_ITEM_TO_IMPORT_DICTIONARY", dictionaryToImport);
    },
    setStatusProgress({ commit }, statusProgress) {
      commit('SET_STATUS_PROGRESS_PROCESS', statusProgress);
    }
  },
  getters: {
    isExecuteProcessModalOpen: (state) => state.isExecuteProcessModalOpen,
  }
})
