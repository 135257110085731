<template>
  <div
    :class="{ 'glass-theme': $vuetify.theme.themes.name === 'glass' }"
    class="layout-wrp"
  >
    <!-- Header -->
    <v-app-bar
      fixed
      color="var(--bgSecondary)"
      :class="{ mobile: $vuetify.breakpoint.smAndDown }"
      ref="headerApp"
    >
      <div>
        <v-tooltip bottom :key="'menu'">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              outlined
              width="30"
              height="30"
              class="close-icon mr-4"
              @click="showMenu = !showMenu"
            >
              <v-icon v-if="showMenu">mdi-close</v-icon>
              <v-icon v-else>mdi-menu</v-icon>
            </v-btn>
          </template>
          <span>{{
            showMenu ? traduceItem("closeMenu") : traduceItem("openMenu")
          }}</span>
        </v-tooltip>
      </div>

      <div class="logo-wrp">
        <router-link :to="{ name: 'ProcessHistory' }" class="d-inline-block">
          <div
            class="logo"
            :style="{
              'background-image': envTheme['--logo'],
            }"
          ></div>
        </router-link>
      </div>

      <div
        v-if="userLogged && userGroups && actualGroup"
        class="btn-wrp d-flex flex-grow-1 justify-end align-center"
      >
        <FreshdeskButton class="freshdesk-button order-first" />
        <v-menu
          v-model="menuGroups"
          left
          offset-y
          :close-on-content-click="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs" class="menuGroups">
              <v-icon>mdi-account-multiple</v-icon>
              <template v-if="!$vuetify.breakpoint.smAndDown">
                {{ actualGroup.name }}
                <v-icon v-if="menuGroups">mdi-menu-up</v-icon>
                <v-icon v-else>mdi-menu-down</v-icon>
              </template>
            </div>
          </template>
          <v-list>
            <v-list-item
              v-for="(group, index) in userGroups"
              :key="index"
              link
              active-class="highlighted"
              :class="group.name === actualGroup.name ? 'highlighted' : ''"
            >
              <v-list-item-title @click="changeUserGroup(group)">
                <v-icon class="mr-2">mdi-account-multiple</v-icon>
                {{ group.name }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu left offset-y>
          <template v-slot:activator="{ on }">
            <v-tooltip bottom :key="'user-configuration'">
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  v-on="{ ...tooltip, ...on }"
                  icon
                  color="var(--fontColorSecondary)"
                  id="userButton"
                  :style="[
                    userLogged && userLogged.photoURL
                      ? {
                          backgroundImage: `url(${userLogged.photoURL})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          width: '35px',
                          height: '35px',
                        }
                      : null,
                  ]"
                >
                  <v-icon v-if="userLogged && !userLogged.photoURL"
                    >mdi-account-circle</v-icon
                  >
                </v-btn>
              </template>
              <span>{{ traduceItem("userConfiguration") }}</span>
            </v-tooltip>
          </template>
          <v-list class="user-menu text-no-wrap">
            <v-list-item id="logoutButton" @click="logOut()">
              <v-icon color="var(--fontColor)" left>mdi-exit-to-app</v-icon>
              {{ traduceItem("logout") }}
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
    <div class="overview">
      <Menu :showMenu="showMenu" @close-menu="(event) => (showMenu = event)" />
      <div
        class="overviewContainer"
        :class="{ 'menu-open': menuOpen && !$vuetify.breakpoint.smAndDown }"
      >
        <div :class="$vuetify.breakpoint.smAndDown ? 'px-4 pb-2' : 'px-5 pb-3'">
          <router-view :key="$route.path">
            <template v-if="this.actualView" v-slot:breadcrumbsWrp="{ item }">
              <Breadcrumbs
                ref="breadcrumbs"
                :dataFromView="item"
                class="breadcrumb-wrp"
                :class="$vuetify.breakpoint.smAndDown ? 'pt-4' : 'pt-5'"
              />
            </template>
          </router-view>
        </div>
      </div>
    </div>

    <!-- Modals -->
    <StepperExecute />
    <AddEditModal />
    <StepperAddEditModal />
    <ImportModal />
    <StepperImportDictionary />
    <ConfirmAction
      :open="itemToConfirmAction ? true : false"
      :title="itemToConfirmAction && itemToConfirmAction.title"
      :question="itemToConfirmAction && itemToConfirmAction.text"
      :primaryButtonText="traduceItem('ok')"
      :secondaryButtonText="traduceItem('cancel')"
      :data="itemToConfirmAction && itemToConfirmAction.data"
      :alerts="itemToConfirmAction && itemToConfirmAction.alerts"
      @confirmation="confirmAction"
      content-class="confirmationDialog confirm"
      ref="confirmAction"
      @keydown.native="handleKeyDown"
      tabindex="0"
    
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { tools } from "@/mixins/tools";
import { theme } from "@/mixins/theme.js";
import Menu from "@/components/Menu";
import Breadcrumbs from "@/components/Breadcrumbs";
import AddEditModal from "@/components/AddEditModal";
import StepperAddEditModal from "@/components/StepperAddEditModal";
import ImportModal from "@/components/ImportModal";
import StepperImportDictionary from "@/components/StepperImportDictionary";
import { firebaseTools } from "@/mixins/firebase-tools";
import StepperExecute from "@/components/StepperExecute";
import FreshdeskButton from "@/components/FreshdeskButton";

export default {
  name: "Layout",
  components: {
    Menu,
    Breadcrumbs,
    AddEditModal,
    StepperAddEditModal,
    ImportModal,
    StepperImportDictionary,
    StepperExecute,
    FreshdeskButton,
  },
  data: () => ({
    menuToShow: [],
    showMenu: true,
    menuGroups: false,
  }),
  computed: {
    ...mapState([
      "userLogged",
      "envTheme",
      "menuOpen",
      "itemToConfirmAction",
      "isExecuteProcessModalOpen",
      "userGroups",
      "actualGroup",
      "actualView",
    ]),
  },
  mounted() {
    this.$refs.breadcrumbs &&
      this.$refs.headerApp &&
      this.setHeaderAndBreadcrumsHeight(
        this.$refs.breadcrumbs.$el.clientHeight +
          this.$refs.headerApp.$el.clientHeight
      );
  },
  created() {
    if (this.actualGroup) this.subscribeOnSnapshotProcess();
    if (this.isExecuteProcessModalOpen)
      this.setIsExecuteProcessModalOpen(false);
    if (this.$vuetify.breakpoint.smAndDown) this.showMenu = false;
  },
  watch: {
    itemToConfirmAction(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          // Asegúrate de que la referencia al componente ConfirmAction esté definida
          console.log("Referencia confirmAction:", this.$refs.confirmAction);

          if (this.$refs.confirmAction) {
            // Obtén el elemento DOM del componente ConfirmAction
            const confirmDialog = this.$refs.confirmAction.$el;
            console.log("Elemento confirmDialog:", confirmDialog);

            // Encuentra el contenedor de botones dentro del modal
            const buttonsContainer = confirmDialog.querySelector('.v-card__actions');
            console.log("Contenedor de botones encontrado:", buttonsContainer);

            // Selecciona todos los botones dentro del contenedor
            const buttons = buttonsContainer ? buttonsContainer.querySelectorAll('.v-btn') : [];
            console.log("Número de botones encontrados:", buttons.length);

            // Aquí puedes hacer lo que necesites con los botones, por ejemplo, enfocarlos
            if (buttons.length > 0) {
              // Enfocar el primer botón, si hay al menos uno
              buttons[0].focus();
              
              console.log('Primer botón enfocado.');
            }
          } else {
            console.error('No se encontró la referencia al componente ConfirmAction.');
          }
        });
      }
    },

    actualGroup() {
      if (
        (this.actualGroup &&
          this.actualView &&
          this.actualView.get &&
          this.actualView.name !== "ProcessHistory" &&
          this.actualView.meta?.parent) ||
        this.actualView.parent
      ) {
        const actualView = this.actualView.parent || this.actualView;
        actualView.get.forEach((item) => {
          this[item.get](
            item.collection,
            item.query ? item.query : null,
            this.$router.currentRoute.params?.id || true
          );
        });
      }
      if (this.actualGroup) {
        this.suscribeToAllViews();
        this.subscribeOnSnapshotProcess();
      }
    },
  },
  methods: {
    ...mapActions([
      "setMenuOpen",
      "setIsExecuteProcessModalOpen",
      "setHeaderAndBreadcrumsHeight",
      "setActualGroup",
      "setLoadingPage",
      "setProcessData"
    ]),
    confirmAction(event) {
      this.executeAction(event);
    },
    changeUserGroup(group) {
      this.setLoadingPage(true);
      this.setProcessData([]);

      this.setActualGroup(group);
      localStorage.setItem("actualGroup", JSON.stringify(group));
      
      this.setLoadingPage(false);

      const actualView = this.actualView.parent || this.actualView;
      if (actualView.meta && actualView.meta.parent) {
        this.$router.push({ name: actualView.meta.parent });
      }
    }
    
  },
  mixins: [tools, theme, firebaseTools],
};
</script>

<style lang="scss" scoped>
.appearance-menu {
  padding: 0;
  
  .v-list-item {
    font-size: 14px;
    min-height: 36px;

    &.selected.theme--dark,
    &.selected.theme--light {
      color: var(--primary) !important;
      .v-icon {
        color: var(--primary) !important;
      }
    }

    .v-icon {
      font-size: 20px;
    }
  }
}

.user-menu {
  ::v-deep.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: var(--fontColor) !important;
  }
  .v-list-item .v-icon {
    font-size: 18px;
  }

  a {
    color: var(--fontColor);
    text-decoration: none;
  }
}

.layout-wrp {
  height: 100%;
  display: flex;
}

.overviewContainer {
  flex: 1 1 auto;
  width: 100%;
  padding-left: 0;

  &.menu-open {
    width: calc(100vw - 260px);
    padding-left: 260px;
  }
  & > div {
    height: 100%;
  }
  & > div > div {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 100%;
  }
}
header {
  z-index: 4 !important;

  &.mobile {
    height: 64px !important;
    ::v-deep {
      .v-toolbar__content {
        height: 64px !important;

        .v-btn.v-btn--icon.v-size--default:not(#userButton) {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}

.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
}

.btn-wrp {
  .v-btn:not(.v-btn--flat):not(.v-btn--text) {
    background-color: transparent;
  }
  .v-btn--icon.v-size--default .v-icon {
    height: 20px;
    font-size: 20px;
    width: 20px;
  }

  #userButton {
    &.v-btn--icon.v-size--default .v-icon {
      height: 35px;
      font-size: 40px;
    }
  }
}

.overview {
  display: flex;
  position: relative;
  width: 100%;
  padding-top: 64px;
}

.close-icon {
  border: 2px solid var(--darkGray);
  &.v-btn--icon.v-size--default .v-icon {
    height: 20px;
    font-size: 20px;
    width: 20px;
  }
}

.glass-theme {
  .close-icon {
    border: 2px solid var(--darkGray) !important;
  }
}

.logo-wrp {
  flex-grow: 1;
}

.logo {
  width: 163px;
  height: 33px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.menuGroups {
  border: 1px solid var(--borderGray);
  border-radius: 24px;
  background: var(--bgSecondary);
  padding: 0 10px;
  height: 38px;
  color: var(--fontColorSecondary);
  margin-right: 10px;
  display: flex;
  align-items: center;
}

::v-deep {
  .highlighted {
    background-color: rgba(156, 64, 199, 0.2) !important;
    .v-list-item__title {
      color: var(--primary) !important;
    }
    i {
      color: var(--primary) !important;
    }
  }
}

.breadcrumb-wrp {
  background-color: var(--bgApplicationColor);
  z-index: 2;
}

@media only screen and (max-width: 960px) {
  .logo-wrp {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.freshdesk-button {
  margin-right: 10px;
}


</style>
