<template>
  <v-dialog
    :value="isExecuteProcessModalOpen"
    :width="800"
    v-click-outside="cancel"
    content-class="containerStepper"
    scrollable
    persistent
  >
    <v-card v-if="steps?.length">
      <v-card-title v-if="stepperConfig?.title">
        {{ stepperConfig.title }}
      </v-card-title>
      <v-card-text class="text-content d-flex">
        <div class="left-stepper" min-height="900">
          <v-stepper v-model="currentStep" vertical flat>
            <div v-for="(step, i) in steps" :key="i" class="step-item">
              <v-stepper-step
                :complete="complete(i)"
                :step="i + 1"
                @click.stop.prevent="selectStep(i + 1)"
              >
                {{ step.title }}
              </v-stepper-step>
              <v-stepper-content
                :complete="complete(i)"
                :step="i + 1"
                :class="{ 'step-resume': step.resume }"
              >
                <template v-if="currentStep > i + 1">
                  <FileToUpload
                    v-if="step.useFileToUploadForResume && currentDataValues[i]"
                    :file="
                      currentDataValues[i] && currentDataValues[i].selectedFile
                    "
                    :wrpClasses="{ medium: true }"
                    @clearFile="clearFile(i)"
                    class="mx-5 py-5"
                  />
                  <steps-resume
                    v-else
                    :items="step.resume"
                    :currentValues="currentDataValues[i]"
                  />
                </template>
              </v-stepper-content>
            </div>
          </v-stepper>
        </div>
        <div class="right-stepper" v-if="isExecuteProcessModalOpen">
          <v-stepper
            :v-model="currentStep"
            vertical
            flat
            :height="'100%'"
            :min-height="'100%'"
          >
            <v-stepper-step :complete="false" :step="currentStep">
              {{ steps[currentStep - 1].title }}
            </v-stepper-step>
            <v-stepper-content
              :step="currentStep"
              class="pl-5"
              :height="'100%'"
            >
              <component
                :key="currentStep"
                :is="componentToShow"
                :submitFormComponent="submitFormComponent"
                :currentDataValues="currentDataValues[currentStep - 1]"
                :previousStepData="
                  (currentStep - 2 >= 0 &&
                    currentDataValues[currentStep - 2]) ||
                  undefined
                "
                :resetComponent="resetComponent"
                :config="steps[currentStep - 1].config"
                @isValid="isValid = $event"
                @dirty="dirty = $event"
                @update="updateValues($event, currentStep - 1)"
                :dataSource="dataSource"
              />
            </v-stepper-content>
          </v-stepper>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="currentStep === 1"
          rounded
          outlined
          @click="cancel('buttonCancel')"
          id="cancel"
          tabindex="-1"
        >
          <v-icon left>mdi-close</v-icon>
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          v-else
          rounded
          outlined
          @click="selectStep(currentStep - 1)"
          id="previous"
          tabindex="-1"
        >
          <v-icon left>mdi-chevron-left</v-icon>
          {{ $t("back") }}
        </v-btn>

        <v-btn
          @click="
            currentStep === steps.length
              ? onSubmit()
              : selectStep(currentStep + 1)
          "
          color="primary"
          rounded
          depressed
          id="last"
          @keydown.tab.prevent="handleTabKey"
        >
          <template v-if="currentStep === steps.length">
            <v-icon left>mdi-play</v-icon>
            {{ $t("execute") }}
          </template>
          <template v-else>
            {{ $t("next") }}
            <v-icon right>mdi-chevron-right</v-icon>
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { tools } from "@/mixins/tools";
import { firebaseTools } from "@/mixins/firebase-tools.js";
import executeConfigStepper from "@/mixins/execute-config-stepper";
import AutocompleteComponent from "../stepperComponents/AutocompleteComponent.vue";
import ImportFile from "../stepperComponents/ImportFile.vue";
import StepsResume from "../stepperComponents/StepsResume.vue";
import WithOrWithoutFilters from "../stepperComponents/WithOrWithoutFilters.vue";
import InputVariablesArrayForm from "../stepperComponents/InputVariablesArrayForm.vue";
import MultipleSelection from "../stepperComponents/MultipleSelection.vue";
import FileToUpload from "@/components/FileToUpload.vue";
import { uploadBytesResumable } from "firebase/storage";

export default {
  name: "StepperExecute",
  mixins: [tools, firebaseTools],
  components: {
    StepsResume,
    AutocompleteComponent,
    InputVariablesArrayForm,
    ImportFile,
    WithOrWithoutFilters,
    MultipleSelection,
    FileToUpload,
  },
  data: () => ({
    dirty: false,
    currentStep: 1,
    fileToTransform: undefined,
    isValid: true,
    submitFormComponent: false,
    currentDataValues: [],
    resetComponent: false,
    dataSource: {},
    progress: 0,
  }),
  computed: {
    ...mapState([
      "isExecuteProcessModalOpen",
      "userLogged",
      "actualGroup",
      "actualView",
    ]),
    steps() {
      return (executeConfigStepper.computed.steps() || []).filter(
        (step, index) =>
          !step.dependsOnPreviousStep ||
          (step.dependsOnPreviousStep && this.currentDataValues[index - 1])
      );
    },
    stepperConfig() {
      return executeConfigStepper.computed.stepperConfig() || {};
    },
    componentToShow() {
      const stepIndex = this.currentStep - 1;
      const { component } = this.steps[stepIndex] || {};

      return component;
    },
  },
  methods: {
    ...mapActions([
      "setIsExecuteProcessModalOpen",
      "setShowSnackBar",
      "setStatusProgress",
    ]),

    selectStep(targetStep) {
      this.submitFormComponent = true;
      if (targetStep <= this.currentStep)
        return (this.currentStep = targetStep);
      this.submitFormComponent = true;

      this.$nextTick(() => {
        if (targetStep === this.currentStep + 1 && this.isValid)
          this.currentStep = targetStep;
        this.submitFormComponent = false;
      });
    },
    complete(i) {
      return this.currentStep > i + 1;
    },

    handleTabKey(event) {
      const nextButton = document.getElementById("last");
      const cancelButton = document.getElementById("cancel");
      const previousButton = document.getElementById("previous");

      if (event.shiftKey && event.key === "Tab") {
        event.preventDefault();

        if (document.activeElement === nextButton && cancelButton) {
          cancelButton.focus();

        } else if (document.activeElement === nextButton && previousButton) {
          previousButton.focus();
  
        }
      }
    },

    handleEscKey(event) {
      if (event.key === "Escape") {
        this.cancel("escCancel");
      }
    },

    cancel(e) {
      if (
        !(
          (e.target && e.target.classList.contains("v-overlay__scrim")) ||
          e === "buttonCancel" ||
          e === "escCancel" || e.key === "Escape"
        ) ||
        !this.isExecuteProcessModalOpen
      )
        return;
      const dirty = this.dirty;

      this.executeAction({
        action: "cancel",
        section: "executeProccess",
        dirty,
      });
    },

    clearFile(index) {
      this.$set(this.currentDataValues, index, {
        ...this.currentDataValues[index],
        selectedFile: undefined,
      });
      this.currentStep = index + 1;
    },
    updateValues(event, index) {
      this.submitFormComponent = false;
      this.$set(this.currentDataValues, index, event);
      const indexOutputDescriptor = this.currentDataValues.findIndex(
        (step) => step?.outputDescriptorInfo
      );
      const indexVariables = this.currentDataValues.findIndex(
        (step) => step?.variables
      );
      if (indexOutputDescriptor === -1 && indexVariables !== -1) {
        this.currentDataValues = [
          ...this.currentDataValues.slice(0, indexVariables),
        ];
      }
    },
    onSubmit() {
      this.submitFormComponent = true;
      let dataToSubmit = null;

      this.$nextTick(() => {
        this.submitFormComponent = false;
        if (this.isValid) {
          /*eslint no-unused-vars: ["error", { "ignoreRestSiblings": true }]*/
          dataToSubmit = this.currentDataValues.reduce(
            (result, stepCurrentValues) => {
              const { selectedFrom, ...stepValue } = stepCurrentValues || {};
              return {
                ...result,
                ...stepValue,
              };
            },
            {}
          );
          this.startExecutionProcess(dataToSubmit);
        }
      });
    },

    async startExecutionProcess({ selectedFile, ...processData }) {
      const { name: inputFileName = "Untitled" } = selectedFile || {};
      const {
        email,
        lang: language,
        displayName: name,
        photoUrl,
      } = this.userLogged;
      const { id: group } = this.actualGroup;
      const user = { email, group, language, name, photoUrl };
      let process = {
        ...processData,
        inputFileName,
        status: "UPLOADING",
        startDate: new Date(),
        numberReadPackages: 0,
        totalNumberPackages: -1,
        user,
      };
      this.setIsExecuteProcessModalOpen(false);
      let processId = await this.insertProcessInDB(process);

      this.uploadFileToTransformToStorage(processId, selectedFile, process);
    },
    insertProcessInDB(process) {
      return this.insertDocument("process", process)
        .then((response) => {
          return response.id;
        })
        .catch((err) => console.log(err));
    },
    uploadFileToTransformToStorage(processId, selectedFile, process) {
      const { name: inputFileName = "Untitled" } = selectedFile || {};
      const fileToUploadRef = this.createStorageReference(
        processId + "/" + inputFileName
      );
      /*
        https://firebase.google.com/docs/storage/web/upload-files?authuser=0
        documentation link
      */
      this.uploadTask = uploadBytesResumable(fileToUploadRef, selectedFile);

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      this.uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          const newProgress = Math.floor(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          
          if (newProgress > this.progress) {
            this.progress = newProgress

            this.setStatusProgress({
              progress: this.progress,
              processId: processId,
            });

            switch (snapshot.state) {
              case "running":
                console.log("Upload is running");
                break;
            }
          }
        },
        (error) => {
          // Handle unsuccessful uploads
          this.progress = 0

          console.log("ERROR: ", error);
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              this.handleFireBaseRequests(error.code);
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              console.log("CANCELLED");
              break;

            default:
              break;
          }
        },
        () => {
          // Handle successful uploads on complete
          this.progress = 0

          this.updateDocument("process", processId, {
            ...process,
            status: "RUNNING",
          }).then(() => {
            this.executeProcess(processId, process);
          });
        }
      );
    },
    executeProcess(processId, { delimiter }) {
      if (!processId) return;
      let params = new FormData();
      params.append("processId", processId);
      params.append("userLanguage", this.userLogged.lang);
      params.append("userGroup", this.actualGroup.id);
      params.append("delimiter", delimiter);
      this.httpRequest(
        "post",
        [window.PROJECT_ENV.BASE_URL_SENDER + "execute", params],
        false,
        true
      )
        .then((response) => {
          if (!response) {
            this.updateDocument("process", processId, { status: "FAIL" });
          }
          const actualView = this.actualView.parent || this.actualView;
          const link =
            actualView.name === "ProcessHistory"
              ? undefined
              : {
                  to: "ProcessHistory",
                  text: this.$i18n.t("seeProcessStatus"),
                };
          const hasValidationError = ["VALIDATION_ERROR", "FAIL"].includes(
            response?.level || response?.data?.level
          );
          if (hasValidationError) {
            this.setShowSnackBar(false);
            const isWarning = ["VALIDATION_ERROR"].includes(
              response?.level || response?.data?.level
            );
            let objectMessage = {
              color: isWarning ? "warning" : "error",
              icon: isWarning ? "mdi-alert" : "mdi-alert-circle",
              msg: (response.msg || response?.data?.msg) + ".",
            };
            if (link) objectMessage.link = link;
            this.setShowSnackBar(objectMessage);
          } else {
            this.handleHttpStatus(response);
          }
        })
        .catch((error) => {
          this.handleHttpStatus(error);
        });
    },
  },
  watch: {
    isExecuteProcessModalOpen(v) {
      if (!v) {
        this.dataSource = {};
        this.currentDataValues = [];
        this.resetComponent = true;
        this.isValid = true;
        this.isDirty = false;
        return;
      } else {
        this.dataSource = this.getDataSource();
        this.currentStep = 1;
      }
      this.resetComponent = false;
    },
  },
  mounted() {
    document.addEventListener("keydown", this.handleEscKey);
  },

  beforeDestroy() {
    document.removeEventListener("keydown", this.handleEscKey);
  },
};
</script>

<style lang="scss" scoped>
.v-dialog__content {
  ::v-deep {
    .containerStepper {
      border-radius: 20px;
      min-height: 95vh;
    }
  }

  .v-card__title {
    font-weight: 400;
    padding: 20px;
  }

  .v-card__actions {
    padding: 20px;
  }
}

.left-stepper,
.right-stepper {
  ::v-deep {
    .v-stepper__step,
    .v-stepper .step-item .v-stepper__step {
      padding: 0px 20px 0px 0px;
      .v-stepper__label {
        font-size: 16px;
        font-weight: 500;
      }
      &:not(.v-stepper__step--active) .v-stepper__label {
        font-weight: 400;
      }
    }
  }
}

.left-stepper {
  width: 45%;
  padding-right: 5px;
  border-right: 1px solid var(--borderGray);
  .v-stepper {
    /* heigth - header - footer */
    height: calc(95vh - 72px - 76px);
    scrollbar-gutter: auto;
    overflow: auto;
  }
  .v-stepper .v-stepper__step {
    cursor: pointer;
    padding-right: 20px;
    align-items: baseline;
  }

  .step-item {
    &:not(:last-child) {
      ::v-deep {
        .v-stepper__content {
          margin-left: 10px !important;
          border-left: 1px solid var(--borderGray) !important;
          min-height: 50px;
        }
      }
    }
    &:not(:first-child) {
      ::v-deep .v-stepper__step__step {
        margin-top: 20px;
      }
    }
    ::v-deep {
      .v-stepper__step {
        cursor: pointer;
        .v-stepper__step__step {
          margin-bottom: 20px;
        }
        .v-stepper__label {
          font-size: 16px;
          font-weight: 400;
        }
      }
      .v-stepper__content[complete].step-resume {
        .v-stepper__wrapper {
          height: auto !important;
        }
      }
    }
  }
}

.right-stepper {
  overflow: auto;
  width: 60%;

  .v-stepper,
  .v-stepper__content {
    height: 100%;
  }
  .v-stepper__step {
    padding-left: 20px;
    ::v-deep .v-stepper__label {
      font-weight: 500;
    }
  }
}

.v-application--is-ltr .v-stepper--vertical .v-stepper__content {
  margin: 0;
  padding: 0;
}

.contenedor {
  width: 100%;
}

::v-deep {
  .v-card__text.text-content {
    padding: 0 20px;
    /* heigth - header - footer */
    height: calc(95vh - 72px - 76px);
  }

  .v-stepper__wrapper {
    overflow: visible;
    height: 100% !important;
  }

  .v-stepper__step {
    &.v-stepper__step--active .v-stepper__label,
    &.v-stepper__step--complete .v-stepper__label {
      color: var(--fontColor);
    }

    &.v-stepper__step--inactive:not(.v-stepper__step--active):not(
        .v-stepper__step--complete
      ):not(.v-stepper__step--error) {
      .v-stepper__step__step {
        background-color: var(--lightGray);
      }
      .v-stepper__label {
        color: var(--fontColorTerciary);
      }
    }
  }

  .v-chip.v-size--small {
    font-size: 14px;
  }

  .v-btn:focus-visible {
    outline: 2px solid #9c40c7;
    outline-offset: 4px;
  }
}
</style>
