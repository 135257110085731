<template>
	<v-skeleton-loader class="d-flex">
		<span class="v-skeleton-loader__bone"></span>
		<v-icon v-if="multiple">mdi-chevron-right</v-icon>
		<span class="v-skeleton-loader__bone" v-if="multiple"></span>
	</v-skeleton-loader>
</template>

<script>
	export default {
		name: "BreadcrumbsSkeleton",
    props: {
      multiple: { type: Boolean, default: false },
    }
	};
</script>

<style scoped>
	div {
		display: flex;
		align-items: center;
		margin: 20px 0;
		gap: 12px;
	}
	span {
		height: 12px;
		border-radius: 3px;
		background-color: #99999950;
		width: 110px;
	}
	.v-icon {
		font-size: 16px;
	}
</style>
