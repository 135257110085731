export default {
  "user": "User",
  "password": "Password",
  "loginUserError": "Invalid username",
  "loginPswdError": "Invalid password",
  "loginEmailError": "Invalid email",
  "login": "Login",
  "loginWithGoogle": "Login with Google",
  "required": "This field is required",
  "internalServerError":
    "An internal server error occurred. Contact with your admin",
  "updateAvailable": 'An update is available',
  "cancel": "Cancel",
  "clone": "Clone",
  "clon": "Clon of {element}",
  "save": "Save",
  "add": "Add",
  "edit": "Edit",
  "delete": "Delete",
  "deleteAll": "Delete all",
  "update": "Update",
  "more": "More",
  "next": "Next",
  "back": "Back",
  "filters": "Filters",
  "columns": "Columns",
  "importColumns": "Import columns",
  "loggedOut": "You have logged out correctly",
  "clickToGoLogin": "Go to login",
  "goBack": "Go back",
  "goProcessHistory": "Go to process history",
  "close": "Close",
  "logout": "Logout",
  "userConfiguration": "User configuration",
  "email": "Email",
  "ok": "Ok",
  "openMenu": "Open menu",
  "closeMenu": "Close menu",
  "deleteLists": "Delete this list",
  "deleteListsQuestion": "Are you sure you want to delete this list?",
  "deleteDictionaries": "Delete this dictionary",
  "deleteDictionariesQuestion": "Are you sure you want to delete this dictionary?",
  "deleteInputDefinitions": "Delete this input definition",
  "deleteInputDefinitionsQuestion": "Are you sure you want to delete this input definition?",
  "deleteOutputDefinitions": "Delete this output definition",
  "deleteOutputDefinitionsQuestion": "Are you sure you want to delete this output definition?",
  "deleteInputDefinitionsDetailComponent": "Delete this column",
  "deleteInputDefinitionsDetailComponentQuestion": "Are you sure you want to delete this column?",
  "deleteOutputDefinitionsDetailComponent": "Delete this column",
  "deleteOutputDefinitionsDetailComponentQuestion": "Are you sure you want to delete this column?",
  "deleteInputDefinitionsDetail": "Delete this input definition",
  "deleteInputDefinitionsDetailQuestion": "Are you sure you want to delete this input definition?",
  "deleteOutputDefinitionsDetail": "Delete this output definition",
  "deleteOutputDefinitionsDetailQuestion": "Are you sure you want to delete this output definition?",
  "deletecolumnsInComponent": "Delete column",
  "deletecolumnsInComponentQuestion": "Are you sure you want to delete this column?",
  "deletecolumnsOutComponent": "Delete column",
  "deletecolumnsOutComponentQuestion": "Are you sure you want to delete this column?",
  "deletefiltersComponent": "Delete filter",
  "deletefiltersComponentQuestion": "Are you sure you want to delete this filter?",
  "deletedeleteSplitConfigurationComponent": "Delete all configuration?",
  "deletedeleteSplitConfigurationComponentQuestion": "Are you sure you want to delete all configuration?",
  "deletedeleteRowComponent": "Delete row",
  "deletedeleteRowComponentQuestion": "Are you sure you want to delete this row?",
  "deletedeleteHeaderCellComponent": "Delete column",
  "deletedeleteHeaderCellComponentQuestion": "Are you sure you want to delete this column?",
  "deletedeleteColumnComponent": "Delete column",
  "deletedeleteColumnComponentQuestion": "Are you sure you want to delete this column?",
  "deleteProcessHistory": "Delete process",
  "deleteProcessHistoryQuestion": "Are you sure you want to delete this process?",
  "cloneInputDefinitions": "Clone input definition",
  "cloneOutputDefinitions": "Clone output definition",
  "cloneDictionary": "Clone dictionary",
  // MENU
  "Home": "Home",
  "ProcessHistory": "Process history",
  "InputDefinitions": "Input definitions",
  "InputDefinitionsDetail": "Input definitions",
  "OutputDefinitions": "Output definitions",
  "Dictionaries": "Dictionaries",
  "Lists": "Lists",
  "error": {
    "sorry": "Sorry",
    "notAllowed": "this user is not allowed",
    "notFound": "this page does not exist",
    "noWorries": "But don't worry, you can always",
    "contactAdmin": "Please, contact with your admin",
    "problem": "there was a problem...",
    "canNotTellWhy": "and I can't tell you why...",
    "emailBlocked": "This user is not authorized. Only users with a corporate NFQ email account can access",
    "add": {
      "Dictionaries": "Unable to add {element} to dictionary",
      "Lists": "Unable to add the list {element}",
      "InputDefinitions": "Unable to add the input definition {element}",
      "OutputDefinitions": "Unable to add the output definition {element}",
      "InputDefinitionsDetail": "Unable to add {element} to input definitions detail",
      "OutputDefinitionsDetail": "Unable to add {element} to output definitions detail",
      "ProcessHistory": "Unable to execute the process. Please try again later",
      "DictionariesDetail": "Unable to add {element} to dictionary"
    },
    "addCellValue": {
      "OutputDefinitionsDetail": "Unable to add the value"
    },
    "editCell": {
      "OutputDefinitionsDetail": "Unable to update the value"
    },
    "edit": {
      "Dictionaries": "Unable to update the dictionary {element}",
      "Lists": "Unable to update the list {element}",
      "InputDefinitions": "Unable to update the input definition {element}",
      "OutputDefinitions": "Unable to update the output definition {element}"
    },
    "delete": {
      "Dictionaries": "Unable to delete the dictionary {element}",
      "Lists": "Unable to delete the list {element}",
      "InputDefinitions": "Unable to delete the input definition {element}",
      "OutputDefinitions": "Unable to delete the output definition {element}",
      "InputDefinitionsDetail": "Unable to delete {element} from input definitions detail",
      "OutputDefinitionsDetail": "Unable to delete {element} from output definitions detail",
      "DictionariesDetail": "Unable to delete the dictionary {element}",
    },
    "clone": {
      "InputDefinitions": "Unable to clone the input definiton {element}",
      "OutputDefinitions": "Unable to clone the output definiton {element}",
      "Dictionary": "Unable to clone the dictionary {element}"
    },
    "addColumn": {
      "InputDefinitionsDetail": "Unable to add the column {element}",
      "OutputDefinitionsDetail": "Unable to add the column {element}"
    },
    "addColumnOut": {
      "OutputDefinitionsDetail": "Unable to add the column {element}"
    },
    "editColumn": {
      "InputDefinitionsDetail": "Unable to update the column {element}",
      "OutputDefinitionsDetail": "Unable to update the column {element}"
    },
    "editColumnOut": {
      "OutputDefinitionsDetail": "Unable to update the column {element}"
    },
    "editParent": {
      "InputDefinitionsDetail": "Unable to update parent in input definitions detail for {element}",
      "OutputDefinitionsDetail": "Unable to update parent in output definitions detail for {element}",
      "DictionariesDetail": "Unable to update the dictionary {element}"
    },
    "deleteElementComponent": {
      "InputDefinitionsDetail": "Unable to delete the column {element}",
      "OutputDefinitionsDetail": "Unable to delete the column {element}"
    },
    "dragging": {
      "OutputDefinitionsDetail": "Unable to modify the position of the column {element}"
    },
    "addColumnSplit": {
      "OutputDefinitionsDetail": "Unable to add the column(s)"
    },
    "editHeaderCell": {
      "OutputDefinitionsDetail": "Unable to update the column {element}",
    },
    "addRow": {
      "OutputDefinitionsDetail": "Unable to add the row"
    },
    "deleteHeaderCell": {
      "OutputDefinitionsDetail": "Unable to delete the column {element}",
    },
    "deleteRow": {
      "OutputDefinitionsDetail": "Unable to delete the row",
    },
    "deleteSplitConfiguration": {
      "OutputDefinitionsDetail": "Unable to delete all settings",
    },
  },
  "noData": {
    "Dictionaries": "There are no dictionaries. Please, remember to import or create a dictionary",
    "Lists": "There are no lists. Please, remember to import or create a list",
    "InputDefinitions": "There are no input definitions. Please, remember to create an input definition",
    "OutputDefinitions": "There are no output definitions. Please, remember to create an output definition",
    "InputDefinitionsDetail": "There are no columns. Please, remember to create a column",
    "OutputDefinitionsDetail": "There are no columns. Please, remember to create a column",
    "ProcessHistory": "There are no processes executed. Please remember to execute a process",
    "allowedValuesValidation": {
      "lists": "There are no lists created",
      "dictionaries": "There are no dictionaries created"
    },
    "columnsIn": "There are no columns. Please, remember to create a column",
    "columnsOut": "There are no columns. Please, remember to create a column",
    "filters": "There are no filters. Please, remember to create a filter",
    "splitConfiguration": "There are no row split. Please remember to create a column first in order split rows",
    "filtersNoActions": "There are no filters. Please remember to create a column first in order to create a filter",
    "DictionariesDetail": "The dictionary is not configured. Click Edit to configure it",
  },
  "searchLabel": {
    "Dictionaries": "Search dictionary",
    "Lists": "Search list",
    "InputDefinitions": "Search input definition",
    "ProcessHistory": "Search process",
    "OutputDefinitions": "Search output definition",
    "InputDefinitionsDetail": "Search column definition",
    "OutputDefinitionsDetail": "Search column definition",
  },
  "DictionariesDetail": "Dictionaries",
  "success": {
    "add": {
      "Dictionaries": "Dictionary {element} added successfully",
      "Lists": "List {element} added successfully",
      "InputDefinitions": "Input definition {element} added successfully",
      "OutputDefinitions": "Output definition {element} added successfully",
      "InputDefinitionsDetail": "Output definition {element} added successfully",
      "OutputDefinitionsDetail": "Input definition {element} added successfully",
      "ProcessHistory": "The process was successfully initiated. Access Process history to download the file",
      "DictionariesDetail": "Dictionary {element} updated successfully",
    },
    "addCellValue": {
      "OutputDefinitionsDetail": "Value added successfully"
    },
    "editCell": {
      "OutputDefinitionsDetail": "Value updated successfully"
    },
    "edit": {
      "Dictionaries": "Dictionary {element} updated successfully",
      "Lists": "List {element} updated successfully",
      "InputDefinitions": "Input definition {element} updated successfully",
      "OutputDefinitions": "Output definition {element} updated successfully",
      "ProcessHistory": "The process was successfully initiated. Access Process history to download the file",
      "DictionariesDetail": "Dictionary {element} updated successfully"
    },
    "delete": {
      "Dictionaries": "Dictionary {element} deleted successfully",
      "Lists": "List {element} deleted successfully",
      "InputDefinitions": "Input definition {element} deleted successfully",
      "OutputDefinitions": "Output definition {element} deleted successfully",
      "InputDefinitionsDetail": "Input definition {element} deleted successfully",
      "OutputDefinitionsDetail": "Ouput definition {element} deleted successfully",
      "DictionariesDetail": "Dictionary {element} deleted successfully",
      "process": "The process has been successfully deleted",
      "allProcess": "All processes have been successfully deleted"
    },
    "clone": {
      "InputDefinitions": "Input definition {element} cloned successfully",
      "OutputDefinitions": "Output definition {element} cloned successfully",
      "Dictionary": "Dictionary {element} cloned successfully"
    },
    "addColumn": {
      "InputDefinitionsDetail": "Column {element} added successfully",
      "OutputDefinitionsDetail": "Column {element} added successfully"
    },
    "addColumnOut": {
      "OutputDefinitionsDetail": "Column {element} added successfully"
    },
    "addFilter": {
      "InputDefinitionsDetail": "Filter {element} added successfully",
    },
    "editColumn": {
      "InputDefinitionsDetail": "Column {element} updated successfully",
      "OutputDefinitionsDetail": "Column {element} updated successfully"
    },
    "editColumnOut": {
      "OutputDefinitionsDetail": "Column {element} updated successfully"
    },
    "editFilter": {
      "InputDefinitionsDetail": "Filter {element} updated successfully",
    },
    "editParent": {
      "InputDefinitionsDetail": "Input definition {element} updated successfully",
      "OutputDefinitionsDetail": "Output definition {element} updated successfully",
      "DictionariesDetail": "Dictionary {element} updated successfully"
    },
    "deleteElementComponent": {
      "InputDefinitionsDetail": "{element} deleted successfully",
      "OutputDefinitionsDetail": "{element} deleted successfully"
    },
    "dragging": {
      "OutputDefinitionsDetail": "The position of the column {element} updated successfully"
    },
    "addColumnSplit": {
      "OutputDefinitionsDetail": "Column(s) added successfully"
    },
    "editHeaderCell": {
      "OutputDefinitionsDetail": "Column {element} updated successfully",
    },
    "addRow": {
      "OutputDefinitionsDetail": "Row added successfully"
    },
    "deleteHeaderCell": {
      "OutputDefinitionsDetail": "Column {element} deleted successfully",
    },
    "deleteRow": {
      "OutputDefinitionsDetail": "Row deleted successfully",
    },
    "deleteSplitConfiguration": {
      "OutputDefinitionsDetail": "All settings deleted successfully",
    },
    "editDictionary": {
      "Dictionaries": "Dictionary {element} updated successfully",
      "DictionariesDetail": "Dictionary {element} updated successfully",
    },
    "addKeyValue": {
      "DictionariesDetail": "Key-value added successfully",
    },
    "deleteKeyValue": {
      "DictionariesDetail": "Key-value deleted successfully",
    },
    "updateKeyValue": {
      "DictionariesDetail": "Key-value updated successfully",
    },
  },
  "addDictionary": "Add dictionary",
  "addList": "Add list",
  "editList": "Edit list",
  "editDictionary": "Edit dictionary",
  "addInputDefinition": "Add definition",
  "addOutputDefinition": "Add definition",
  "editInputDefinitions": "Edit input definition",
  "addInputDefinitions": "Add input definition",
  "addOutputDefinitions": "Add output definition",
  "editOutputDefinitions": "Edit output definition",
  "name": "Name",
  "selectAll": "Select all",
  "unselectAll": "Unselect all",
  "noSearchData": "No results matching your search",
  "discardChangesTitle": "Discard changes",
  "discardChangesText": "Are you sure you want to discard changes?",
  "fieldRequired": "This field is required",
  "all": 'All',
  "nameAlreadyExists": 'This name is repeated',
  "hasBeenProblemTryAgain": 'There has been a problem and we cannot show you the data. Try again later',
  "unexpectedError": "Sorry, an unexpected error has occurred. If it persists, contact with your admin",
  "resultsFound": "Results found",
  "expiredSession": "Your session has expired. Please sign in again",
  "alertNoChanges": "You have to make any changes before saving",
  "executeProcess": "Execute process",
  "trafis-ui": "NFQ Trafis",
  "key": "Key",
  "value": "Value",
  "valueRepeated": "This value is repeated",
  "keyRepeated": "This key is repeated",
  "columnRepeated": "This column is repeated",
  "status": "Status",
  "date": "Date",
  "userImage": "User",
  "startTime": "Start time",
  "endTime": "End time",
  "inputFileName": "Input file name",
  "delimiterColumns": "Column separator",
  "processId": "Process id",
  "inputDefinitionName": "Input definition",
  "outputDefinitionNames": "Output definitions",
  "download-file": "Download file",
  "download-validations": "Download validations",
  "download-template": "Download template",
  "fileType": "File type",
  "headerRow": "Header row",
  "headerRowTooltip": "If you do not report this field, it will be assumed that the data starts in the first row of the file",
  "optionalParameters": "Optional parameters",
  "defaultDatePattern": "Date format",
  "defaultDatePatternTooltip": "If you do not report this field in one or more Date type columns, the default format will be assumed to be able to read them",
  "defaultDecimalSeparator": "Decimal separator",
  "defaultDecimalSeparatorTooltip": "If you do not report this field in one or more Number type columns, the decimal separator will be assumed by default to be able to read them",
  "comma": "Comma",
  "dot": "Dot",
  "fileName": "File name",
  "alias": "Input name",
  "columnReferenceColumnName": "Input name",
  "position": "Position",
  "dataType": "Data type",
  "nullable": "Nullable",
  "COMMA": "Comma",
  "DOT": "Dot",
  "EMPTY": "Empty",
  "NOT_EMPTY": "Not empty",
  "REGULAR_EXPRESSION": "Regular expression",
  "columnsDefinitions": "Columns definition",
  "filtersDefinitions": "Filters definition",
  "addColumn": "Add column",
  "addColumnOut": "Add output column",
  "addColumns": "Add columns",
  "addFilter": "Add filter",
  "positionTooltip": "If you do not report this field, the position will be filled automatically with its position in the list",
  "sumValues": "Sum values",
  "optionalValidations": "Optional validations",
  "outputAlias": "Output name",
  "transformationType": "Transformation type",
  "OutputDefinitionsDetail": "Output definitions",
  "datePattern": "Date format",
  "datePatternTooltip": "This field is required if you select the Date data type and as long as you have not reported the default value when creating the definition",
  "decimalSeparator": "Decimal separator",
  "decimalSeparatorTooltip": "This field is required if you select the Double or Percentage data type and as long as you do not specify the default value when creating the definition",
  "sumValuesTooltip": "If you activate this switch, an accumulation of the valid and invalid rows of this column will be carried out",
  "positionAlreadyExists": "The position is already in use",
  "editColumn": "Edit column",
  "editColumnOut": "Edit output column",
  "editFilter": "Edit filter",
  "conditions": "Conditions",
  "condition": "Condition",
  "selectedFilterNames": "Filters",
  "selectFilters": "Select, if you want to, one or many filters",
  "definitionHasNoFilters": "This definition has no filters associated",
  "validationOptionalType": "Optional validation type",
  "allowedValuesValidation": "Allowed values",
  "range": "Range",
  "from": "From",
  "to": "To",
  "rangeType": "Range type",
  "OPEN": "Open",
  "CLOSE": "Close",
  "OPEN_CLOSE": "Open - Close",
  "CLOSE_OPEN": "Close - Open",
  "allowedValuesValidationTooltip": "In this field you can associate a dictionary or a list of values to validate that your values belong to that dictionary (keys) or that list",
  "runProcess": "Run process",
  "selectFileToTransform": "Select the file you want to transform",
  "selectFile":"Select file",
  "yourPc": "Your PC",
  "googleDrive": "Google Drive",
  "selectInputDefinition": "Select an input definition",
  "selectedInputDefinition": "Input definition selected",
  "selectOneOrMoreOutputDefinition": "Select one or more output definitions",
  "youMustSelectAnImportOption": "You must select an import option",
  "importFileFromYourPc": "Import file from your PC",
  "clickOrDragToImport": "Click or drag to import",
  "youMustSelectAtLeastOneFileToImport": "You must select at least one file to import",
  "inputDefinitions": "Input Definition",
  "outputDefinitions": "Output definitions",
  "splitConfiguration": "Row split",
  "splitConfigurationDefinitions": "Row split",
  "selectOutputDefinitions": "Select one or more output definitions only if you want to transform the file",
  "noDataAutocomplete": "No data available",
  "extensionErrorFile": "The extension of this file is not supported. You must select a .{element} file",
  "runningProcess": "The process is running",
  "seeProcessStatus": "Access the Historical processes screen to find out it status",
  "seeProcesses": "Access the Historical processes screen to download the file",
  "addTenor": "Add tenor",
  "dictionary": "Dictionary",
  "dateColumnName": "Date column",
  "timeStepColumnName": "Tenor column",
  "tenor": "Tenor",
  "limitDate": "Limit date",
  "operator": "Operator",
  "aliasTooltip": "This field is required if you want to modify the input alias",
  "decimalSeparatorTooltipOutput": "This field is required if you want to modify the decimal separator",
  "datePatternTooltipOutput": "This field is required if you want to modify the date format",
  "limitDateTooltipOutput": "If you report this field, the tenor will be added to the value of your column until the deadline is reached",
  "dictionaryName": "Dictionary name",
  "finishProcess": "The process has been executed successfully",
  "seeFileProcesses": "Access the Historical processes screen to download the file",
  "INTEGER": "Integer",
  "DOUBLE": "Double",
  "DATE": "Date",
  "STRING": "String",
  "BOOLEAN": "Boolean",
  "PERCENTAGE": "Percentage",
  "MAIL": "Mail",
  "noDataInfoColumn": "There is no more information about this column",
  "rangeValidation": "Range validation",
  "validationOfAllowedValues": "Validation of allowed values",
  "parametersTransfDictionary": "Dictionary transformation parameters",
  "parametersTransfAddTenor": "Add tenor transformation parameters",
  "timeStepValue": "Tenor",
  "arithmeticOperator": "Operator",
  "ADD": "+",
  "SUBTRACT": "-",
  "DICTIONARY": "Dictionary",
  "ADD_TIME_STEP": "Add tenor",
  "TENOR": "Tenor",
  "continue": "Continue",
  "execute": "Execute",
  "errorExecuteProcess": "The process could not be executed.",
  "modifyPosition": "Modify position",
  "keepPosition": "Keep position",
  "disabledDragTooltip": "You can modify the position by clicking on the button 'Modify position'",
  "disabledDragSearchingTooltip": "This action is not available when you apply filters in the search engine",
  "disabledExpandTooltip": "This action is not available when you modify the position",
  "yes": "Yes",
  "no": "No",
  "valueGreaterThanZero": "This value must be greater than 0",
  "valueCombinationRepeated": "This combination of input alias and output alias already exists",
  "addFilters": "Add filter",
  "editFilters": "Edit filter",
  "indicateFilterName": "Indicate the filter name",
  "filterName": "Filter name",
  "defineConditions": " Define its conditions",
  "firstTerm": "Indicate the first term",
  "indicateConditional": "Indicate the conditional",
  "conditional": "Conditional",
  "secondTerm": "Indicate the second term",
  "columnAlias": "Column alias",
  "indicateConditionalNotOk": "Indicate the value if the condition is not met",
  "reviewValidationsAttr": "Review the validations of the form to be able to add {attr}",
  "alertEditDictionary": "Before editing the dictionary, remember that it may be associated with some input definition",
  "theFilter": "the filter",
  "theColumn": "the column",
  "theValue": "the value",
  "indicateColumnName": "Indicate the name of the column",
  "nameColumn": "Column name",
  "defineCreateColumn": "Define how to create the column",
  "defineCreateValue": "Define how to create the value",
  "withOutConditions": "Without conditions",
  "withConditions": "With conditions",
  "createColumn": "Create column",
  "defineHowSetValue": "Define how to assign the value",
  "assignValue": "Assign value",
  "assignValueToElement": "@:assignValue {element}",
  "concatenateValues": "Concatenating values",
  "defineCases": "Define the cases",
  "inputDefinitionAssociate": "Associate, if you want, an input definition",
  "inputDescriptorReferenceTooltip": "If you associate an input definition it will be easier for you to create the output columns",
  "deleteAllProcess": "Delete processes",
  "deleteAllProcessTitle": "Delete all processes",
  "deleteAllProcessQuestion": "Are you sure you want to delete all processes? Running processes will not be deleted",
  "deleteProcessTitle": "Delete process",
  "deleteProcessQuestion": "Are you sure you want to delete this process?",
  "downloadingFile": "The file is downloading",
  "errorDownloadFile": "Unable to download the file",
  "inputColumnName": "Input column name",
  "tenorColumnName": "Name of a Tenor type column",
  "tenorInputValue": "Fixed value for Tenor",
  "indicateDateToReach": "Indicate, if you want, the date you want to reach",
  "limitDateColumnName": "Name of a Date type column",
  "limitDateInputValue": "Fixed value for Date",
  "columnReferenced": "Referenced column",
  "valuesToConcatenate": "Values to concatenate",
  "caseConditions": "Conditions case",
  "firstTermField": "First term",
  "secondTermField": "Second term",
  "assignValueFirstTerm":"Assign value first term",
  "assignValueSecondTerm":"Assign value second term",
  "case": "Case",
  "notConditionsApllied": "No conditions applied",
  "alertInfoConditionsEmpty": "If you don't add any conditions, this case will apply when the conditions are not met",
  "alertErrorConditionsEmpty": "You can not add more than one case without conditions applied",
  "eachCase": "With conditions",
  "addTimeStepTransformation": "Add tenor",
  "dictionaryTransformation": "Dictionary",
  "joinColumns": "Concatenating values",
  "INPUT_VALUE": "Fixed value",
  "COLUMN_NAME": "Referencing a column",
  "INPUT_COLUMN_NAME": "Referencing an input column",
  "OUTPUT_COLUMN_NAME": "Referencing an output column",
  "WILDCARD": "Wildcard",
  "assignedValue": "Assigned value",
  "assignedValueBy": "Assigned value by",
  "tenorValue": " for the tenor",
  "targetDate": "Target date",
  "columnsIn": "Search column definition",
  "columnsOut": "Search column definition",
  "noConditionsApplied": "No conditions applied",
  "inputDescriptorReference": "Input definition",
  "conditionals": "Conditionals",
  "selectImportFile": "Choose the type of file you are going to import",
  "successfulImport": "File {element} imported successfully",
  "uploadedImport": "File {element} is being imported",
  "sheetName": "Document tab name xlsx",
  "columnsToRead": "Indicate the columns that should be read",
  "rowsToRead": "Indicate the rows that should be read",
  "inputAlias": "Column name Alias",
  "positionColumn": "Column name Position",
  "mandatoryColumn": "Column name Required",
  "dataTypeColumn": "Column name Data type",
  "filter": "Filter",
  "filterColumnName": "Column alias",
  "filterColumnNameSection": "Indicate the alias of the column on which the filter is applied",
  "filterColumnValue": "Indicate the value",
  "selectFileToImport": "Select the file to import",
  "import": "Import",
  "addRow": "Add row",
  "rowColumnInformation": "Information Row {index} - {column}",
  "valueAssigned": "Assigned value by {item}",
  "row": "Row",
  "rows": "Rows",
  "addCellValue": "Add value {element}",
  "defineHowToCreateValue": "Define how to create the value",
  "createValue": "Create value",
  "editCellValue": "Edit value {element}",
  "shouldBeGreatherThan": "The value should be greater than {element}",
  "withoutFilters": "Without filters",
  "withFilters": "With filters",
  "filterByColumn": "Filtered from a column",
  "fixedValue": "Fixed value",
  "wildcard": "Wildcard",
  "columnReference": "Referencing a column",
  "columnReferenceInput": "Referencing an input column",
  "columnReferenceOutput": "Referencing an output column",
  "onlyAvailableOneOfValues": "You can only modify one of the two values",
  "validationOf": "Validation of {validation}",
  "importDictionary": "Import dictionary",
  "dictionaryDetail": "Dictionary detail",
  "addKeyValue": "Add key-value",
  "maximizeTable": "Maximize table",
  "VALIDATION_DUPLICATED_KEYS_SNACKBAR": "Review the validations so you can add the key-value set",
  "VALIDATION_DUPLICATED_KEYS_TOOLTIP": "There cannot be 2 identical key combinations",
  "restoreTable":"Restore table",
  "deleteDictionariesDetail":"Delete this dictionary",
  "deleteDictionariesDetailQuestion":"Are you sure to delete this dictionary?",
  "deleteDictionaryAlert":"This dictionary is associated with one or more columns. If you delete it, those associations will disappear",
  "invalidDate": "Invalid date",
  "columnSeparatorStep": "Indicate the column separator",
  "TAB":"Tab",
  "SPACE":"Space",
  "PIPE":"Pipe",
  "SEMICOLON":"Semicolon",
  "delimiterLabel": "Column separator",
  "inputDescriptorInfoLabel": "Input definition",
  "or": "or",
  "applyVar": "Apply variable",
  "nameVar": "Variable name",
  "defineVariables": 'Define the variables',
  "alertInfoInputVariablesArrayFormText": "Remember that if there is more than one variable with the same name, we only show one because the same value will be assigned to all of them",
  "noVariables": "There are no variables to define",
  "definitions": "definitions",
  "plusNElements": "(+{number} {element})",
  "assignDictionaryKey": "Assign each key of the dictionary with its input column",
  "dictionaryFormWarning": "Remember that the name you assign to this column is not the one you have indicated, but the one corresponding to each value column of the dictionary",
  "indicateDictionaryName": "Indicate the dictionary name",
  "defineDictionaryKeys": "Define this Key fields",
  "defineDictionaryValues": "Define this Value fields",
  "keyField": "Key field",
  "valueField": "Value field",
  "deleteKeyTitle": "Delete key",
  "deleteKeyQuestion": "Are you sure you want to delete this key?",
  "deleteKeyAlert": "If you delete this key, its associated values ​​will also be deleted",
  "deleteValueTitle": "Delete value",
  "deleteValueQuestion": "Are you sure you want to delete this value?",
  "deleteValueAlert": "If you delete this value, its associated values ​​will also be deleted",
  "modifiedDictionaryAlert": "Remember to modify the output columns that have this dictionary associated",
  "deleteDictionarySettingTitle": "Delete all dictionary settings",
  "deleteDictionarySettingQuestion": "Are you sure to remove all dictionary settings?",
  "deleteDictionaryKeyValueTitle": "Delete this key-value",
  "deleteDictionaryKeyValueQuestion": "Are you sure to delete this key-value?",
  "elementDoesntExistWarning": "This item no longer exists",
  "withOrWithoutConditionsTitle": "Define how to create the column",
  "showInFinalReport": "Show in final report",
  "VARIABLE": "Apply variable",
  "appliedVar": "Applied variable",
  "inputTextLabel": "Nombre",
  "startDate": "Start date",
  "finalDate": "Maturity date",
  "finalDateOptional": "Maturity date, this value is not required",
  "endDate": "End date",
  "indicateVirtualColumn": "Indicate if you want to show this column in the final report",
  "dateDecimalTitle": "Modify, if you want, the date format or the decimal separator",
  "referencedInputColumn": "Referenced input column",
  "referencedOutputColumn": "Referenced output column",
  "noDataColumnsCombobox": "There are no columns",
  "variables": "Variables",
  "support": "Support",
  "noDataSelects": {
    "definitions": "There are no definitions",
    "filters": "There are no filters",
    "dictionaries": "There are no dictionaries"
  },
  "infoIn": "Information",
  "infoOut": "Information",
  "infoAlertFilters": "Remember that the filters you apply in the execution will eliminate the rows that fulfill the conditions",
  "arithmeticTransformation": "Mathematical operation",
  "inputColumnsRef": "${ } input columns",
  "outputColumnsRef": "$${ } output columns",
  "operation": "Operation",
  "reviewMathOpInfo": "Check the instructions to generate mathematical operations",
  "reviewMathOpInfoStep1": "You can reference <b>input columns</bold></b> with <b>${inputColumn}</b>",
  "reviewMathOpInfoStep2": "You can reference <b>output columns</b> with <b>$${outputColumn}</b>",
  "reviewMathOpInfoStep3": "The <b>operators</b> you can use are <b>[ ] ( ) + - * /</b>",
  "reviewMathOpInfoStep4": "You can use <b>any numeric value</b>",
  "reviewMathOpInfoExample": "(${ ce1 } + $${ cs3 })* 100",
  "example": "Example",
};
