<template>
	<section class="mt-5 ml-1">
		<div v-for="(item, i) in addEditConfiguration" :key="i">
			<h3 class="formTitle" v-if="item.title">{{ item.title }}</h3>
			<component
				:is="item.component"
				:title="item.title"
				:name="item.name"
				:dataSource="dataSource"
				:config="item.config"
				:currentValues="defineColumnComponentValues"
				:globalValues="globalValues"
				:currentDataValues="currentDataValues"
				:submitFormComponent="submitFormComponent"
				:getStatus="getStatus"
				@update="updateValues($event, i)"
				@isValidForm="isValidForm($event, i)"
				@dirty="onDirty" />
		</div>
	</section>
</template>

<script>
	import DateDecimalForm from "@/stepperComponents/DateDecimalForm.vue";
	import InputText from "@/stepperComponents/InputText.vue";
	import WithOrWhitoutConditions from "./WithOrWhitoutConditions.vue";
	import SwitchForm from "@/stepperComponents/SwitchForm.vue";
	import { tools } from "@/mixins/tools";

	export default {
		name: "DefineColumn",
		components: {
			DateDecimalForm,
			InputText,
			WithOrWhitoutConditions,
			SwitchForm,
		},
		mixins: [tools],
		props: {
			currentValues: { type: Object },
			globalValues: { type: Array },
			currentDataValues: { type: Object },
			dataSource: { type: Object },
			config: { type: Object },
			submitFormComponent: { type: Boolean },
			getStatus: { type: Boolean },
		},
		data() {
			return {
				validComponent: [],
				defineColumnComponentValues: [],
				dirtyComponent: false,
			};
		},
		methods: {
			updateValues(event, i) {
				this.defineColumnComponentValues[i] = event;
				this.emitValues();
				this.emitIsValid();
			},
			isValidForm(event, i) {
				this.validComponent[i] = event.valid;
				this.emitIsValid();
			},
			emitIsValid() {
				this.$emit("isValidForm", {
					valid: this.validComponent?.every((el) => el === true),
				});
			},
			emitValues() {
				let data = {};
				this.defineColumnComponentValues?.forEach((el) => {
					if (el) data = { ...data, ...el };
				});
				this.$emit("update", data);
			},
			onDirty(event) {
				if (!this.dirtyComponent && event) {
					this.dirtyComponent = event;
					this.$emit("dirty", this.dirtyComponent);
				}
			},
		},
		computed: {
			addEditConfiguration() {
				return [
					{
						title: this.$i18n.t("indicateColumnName"),
						name: "indicateColumnName",
						component: "InputText",
						config: {
							label: this.$i18n.t("nameColumn"),
							formAttribute: "alias",
							validators: {
								required: {
									msg: this.$i18n.t("required"),
								},
								alreadyExists: {
									msg: this.$i18n.t("nameAlreadyExists"),
								},
							},
						},
						parentAttributes: ["alias"],
					},
					{
						title: this.$i18n.t("indicateVirtualColumn"),
						name: "indicateVirtualColumn",
						config: {
							label: this.$i18n.t("showInFinalReport") + ":",
							formAttribute: "displayColumn",
							defaultValue: true,
						},
						component: "SwitchForm",
						parentAttributes: ["displayColumn"],
					},
					{
						title: this.$i18n.t("withOrWithoutConditionsTitle"),
						name: "withOrWithoutConditions",
						component: "WithOrWhitoutConditions",
						parentAttributes: ["WithOrWhitoutConditions"],
					},
					{
						title: this.$i18n.t("dateDecimalTitle"),
						name: "dateDecimal",
						component: "DateDecimalForm",
						parentAttributes: ["decimalSeparator", "datePattern"],
					},
				];
			},
		},
		watch: {
			submitFormComponent(v) {
				this.submitComponent = v;
				this.$nextTick(() => {
					this.emitIsValid();
				});
			},
			getStatus(v) {
				this.submitComponent = v;
				this.$nextTick(() => {
					this.emitIsValid();
				});
			},
		},
		created() {
			this.validComponent.fill(false, 0, this.addEditConfiguration.length);
			this.defineColumnComponentValues.fill(
				null,
				0,
				this.addEditConfiguration.length,
			);

			if (
				this.currentDataValues &&
				Object.keys(this.currentDataValues)?.length
			) {
				const addEditConfig = this.deepCopyFunction(this.addEditConfiguration);
				this.defineColumnComponentValues = addEditConfig.map((el) => {
					let obj = {};
					el.parentAttributes.forEach((attr) => {
						if (this.currentDataValues[attr])
							obj[attr] = this.currentDataValues[attr];
					});
					if (!Object.keys(obj).length) return null;
					return obj;
				});
			}
			this.emitValues();
		},
	};
</script>

<style lang="scss" scoped>
	.formTitle {
		font-size: 14px;
		font-weight: 500;
		margin-bottom: 20px;
		color: var(--secondary);
	}
</style>
