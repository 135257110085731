<template>
  <v-speed-dial
    :direction="'right'"
    :transition="'slide-x-transition'"
    open-on-hover
  >
    <template v-slot:activator>
      <span>
        {{ valueToShow || ('--')}}
      </span>
    </template>
    <template 
      v-for="( action, index) in actions"
    >
      <v-btn
        v-if="(!valueToShow && action.emptyValue) || (valueToShow && !action.emptyValue)"
        :key="index"
        fab
        icon
        x-small
        :color="'var(--fontColorSecondary)'"
        :height="'10px'"
        :width="'10px'"
        @click.native.stop="$emit('onActionSelected', action)"
      >
        <v-icon>{{action.icon}}</v-icon>
      </v-btn>
    </template>
  </v-speed-dial>
</template>

<script>

export default {
  name: "QuickActions",
  props: {
    valueToShow: { type: String },
    actions: { type: Array }
  }
}
</script>
