<template>
  <CustomErrorCard
    :mainText="'error.notAllowed'"
  />
</template>

<script>
import CustomErrorCard from "@/components/CustomErrorCard";

export default {
  name: "NotAllowed",
  components: { CustomErrorCard },
};
</script>
