<template>
  <div class="container px-0 mx-0" :class="[wrpClasses, !hasCard ? 'noCard' : '']">
    <component :is="hasCard ? 'Card' : 'div'">
      <component :is="hasCard ? 'CardContent' : 'div'" >
        <CardHeader class="card-header" :class="!tableActions || (tableActions && !tableActions.length) ? 'py-5' : ''">
          {{ cardHeaderText }}
            <template v-slot:afterHeader v-if="tableActions && tableActions.length">
              <Actions :actions="tableActions" @action-selected="onActionSelected"/>
            </template>
        </CardHeader>
        <div class="icon-container" :style="{ width: width, 'max-width': maxWidth }">
          <component :style="{ 'max-width': 'calc(' + maxWidth + ' - 120px)' }" :is="iconPath" :color="envTheme['--primary']"/>
          <span>{{ text }}</span>
        </div>
      </component>
    </component>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {tools} from '../../mixins/tools'

export default {
  name: "NoData",
  props: {
    image: { type: String, required: true },
    text: { type: String, required: true },
    width: { type: String, default: "100%" },
    maxWidth: { type: String, default: "420px" },
    hasCard: { type: Boolean, default: true },
    wrpClasses: { type: String },
    tableActions: { },
    cardHeaderText: { type: String, default: null }
  },
  computed: {
    ...mapState(["envTheme", "currentVuetifyTheme", "actualView"]),
    iconPath() {
      return () => import(`../../../public/img/no_data_images/${this.image}.svg`);
    },
  },
  methods:{
    onActionSelected(event){
      this.$emit("actionSelected", event)
    }
  },
  mixins: [tools]
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 100%;
  .card-content {
    padding: 0;
    text-align: center;
    > div {
      margin: 0 auto;
    }
  }
  span {
    color: var(--lightGray);
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    display: inline-block;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  svg {
    margin-top: 20px;
  }

  .glass-theme {
    .container {
      background: red;
    }
    .icon-container > span {
      color: var(--labelsColors);
    }
  }

  @media only screen and (max-width: 960px) {
    span {
      margin-bottom: 0;
    }
    svg {
      margin-top: 0;
    }
  }
}

.noCard {
  padding: 0;
  .icon-container {
    text-align: center;
    margin: 0 auto;
  }
  span {
    margin-bottom: 0;
  }
}
</style>