<template>
      <v-skeleton-loader class="d-flex flex-column text-card-loader">
        <span class="v-skeleton-loader__bone pair-text" :style="{ backgroundColor: colors[0] }">
        </span>

        <span class="v-skeleton-loader__bone odd-text" :style="{ backgroundColor: colors[1] }">
        </span>

        <span class="v-skeleton-loader__bone pair-text" :style="{ backgroundColor: colors[2] }">
        </span>

        <span class="v-skeleton-loader__bone odd-text" :style="{ backgroundColor: colors[3] }">
        </span>

        <span class="v-skeleton-loader__bone pair-text" :style="{ backgroundColor: colors[4] }">
        </span>
      </v-skeleton-loader> 
</template>

<script>
	export default {
		name: "ListViewSkeleton",
    data() {
      return {
        colors: ["#99999980", "#99999966", "#9999994D", "#99999933", "#9999991A"],
      };
    },

	};
</script>

<style lang="scss" scoped>
	.v-skeleton-loader__bone {
		border-radius: 6px;
    height: 20px;
	}
  .odd-text {
    width: 143px;
  }
  .pair-text {
    width: 191px;
  }
  .text-card-loader {
    gap: 20px;
    padding: 0 20px;
  }
</style>