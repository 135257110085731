<template>
  <div>
    <v-text-field ref="inputText" :label="fieldInputText.label" v-model="currentValues[fieldInputText.name]"
      :type="'text'" @input="update($event)" :rules="[...fieldInputText.validations]" class="inputText"
      @update:error="getError($event)" ></v-text-field>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";

export default {
  name: "InputText",
  data: () => ({
    dirty: false,
    currentValues: {},
    inputName: "inputText",
    hasError: false,
    initialValue: undefined
  }),
  props: {
    currentDataValues: { type: Object },
    resetForm: { type: Boolean },
    dataSource: { type: Object },
    config: { type: Object },
    submitFormComponent: { type: Boolean },
    getStatus: { type: Boolean },
    globalValues: { type: Array },
  },
  methods: {
    getError(error) {
      if (this.hasError !== error) this.hasError = error;
      else this.$emit("isValidForm", { valid: !error });
    },
    update(event, fromCreated = false) {
      if (!fromCreated) this.dirty = true;
      let data;
      if (event) {
        data = {
          [this.inputName]: event
        }
      } else {
        data = null;
        this.$emit("isValidForm", { valid: false });
      }
      this.$emit("dirty", this.dirty);
      this.$emit("update", data);
    },
    required({ msg }) {
      return v => {
        if (v || v === 0)
          return Array.isArray(v) && v.length === 0 ? msg : true;
        return msg;
      }
    },
    alreadyExists({ msg }) {
      return v => {
        const exists = [...(this.globalValues || [])].some(item => item[this.inputName] === v && v !== this.initialValue)
        return !(v && exists) || msg;
      }
    },
    setCurrentValues(fromCreated = false) {
      if (this.currentDataValues && this.currentDataValues[this.inputName]) {
        this.currentValues = { [this.inputName]: this.currentDataValues[this.inputName] };
        if (fromCreated) this.initialValue = this.currentDataValues[this.inputName]
      } else this.currentValues = {};
    },
    getFormStatus() {
      this.$emit("dirty", this.dirty);
    },
  },
  computed: {
    fieldInputText() {
      const { label = i18n.t("inputTextLabel"), validators = {} } = this.config || {}
      return {
        name: this.inputName,
        label: label,
        dataSource: this.dataSource.columnsReferenced,
        validations: [...(Object.keys(validators) || []).map(validator => {
          return this[validator](this.config.validators[validator]);
        })]
      }
    }
  },
  created() {
    if (this.config?.formAttribute) this.inputName = this.config.formAttribute;
    this.setCurrentValues(true);
    this.update(this.currentDataValues && this.currentDataValues[this.inputName], true);
  },
  watch: {
    currentDataValues() {
      this.setCurrentValues();
    },
    resetForm() {
      this.$refs.inputText.reset();
    },
    getStatus(val) {
      if (val) this.getFormStatus();
    },
    submitFormComponent(val) {
      if (val) {
        this.$refs.inputText.hasFocused = true;
        this.$refs.inputText.validate();
        this.getFormStatus();
      }
    },
    hasError: {
      immediate: true,
      handler(val) {
        this.$emit("isValidForm", { valid: !val });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.inputText {
  margin-top: 10px;
  padding-bottom: 10px;
}
</style>
