<template>
  <div class="d-flex flex-row switchForm">
    <span v-if="label" class="label">{{ label }}</span>
    <v-switch
      ref="switch"
      class="switch"
      v-model="switchValue"
      @change="update()"
      hide-details
    />
  </div>
</template>

<script>
export default {
  name: "SwitchForm",
  data: () => ({
    switchValue: false,
    dirty: false,
  }),
  props: {
    config: { type: Object, required: true },
    currentDataValues: { type: Object },
    resetForm: { type: Boolean },
    getStatus: { type: Boolean },
  },
  watch: {
    resetForm() {
      this.$refs.switch.reset();
    },
    getStatus(val) {
      if (val) this.$emit("dirty", this.dirty);
    },
  },
  computed: {
    defaultValue() {
      const { defaultValue } = this.config || {};
      return defaultValue;
    },
    label() {
      const { label } = this.config || {};
      return label;
    },
    formAttribute() {
      const { formAttribute } = this.config || {};
      return formAttribute;
    },
  },
  methods: {
    update(fromCreated = false) {
      if (!fromCreated) this.dirty = true;
      this.$emit("dirty", this.dirty);
      this.$emit("isValidForm", { valid: true });
      this.$emit("update", { [this.formAttribute]: this.switchValue });
    },
    setCurrentValues() {
      const { [this.formAttribute]: currentValue } = this.currentDataValues || {};
      let value = typeof currentValue === "boolean" ? currentValue : this.defaultValue;
      this.switchValue = Boolean(value);
    },
  },
  created() {
    this.setCurrentValues();
    this.update(true);
  },
};
</script>

<style lang="scss" scoped>
.switchForm {
  padding-bottom: 20px;
  .switch {
    margin: 0;
    padding: 0;
  }
  .label {
    color: var(--fontColorTerciary);
    margin-right: 10px;
  }
}
</style>
