<template>
  <v-form ref="form" @submit.prevent>
    <v-autocomplete 
      :items="items" 
      :item-text="['name']" 
      :item-value="itemValue" 
      v-model="selectedItem" 
      :label="label"
      :rules="[v => !!v || $t('required')]" 
      class="mt-5"
      :no-data-text="$t(noDataText)"
      :autofocus="true"
      ref="autocomplete"
      @focus = "openMenu"
    >
      <template  v-slot:item="{ attrs, item }" >
				<v-list-item-content >
					<v-list-item-title  >
						<v-tooltip
							bottom
							:disabled="!isTooltipActivated(`${attrs.id}ListItem`)">
							<template v-slot:activator="{ on }" >
								<div
									class="text-truncate"
									v-on="on"
									@mouseover="
										setTooltipActivated($event, `${attrs.id}ListItem`)
									">
									{{ item.name || item }}
								</div>
							</template>
							<span >{{ item.name || item }}</span>
						</v-tooltip>
					</v-list-item-title>
				</v-list-item-content>
			</template>
    </v-autocomplete>
  </v-form>
</template>

<script>
export default {
  name: 'AutocompleteComponent',
  props: {
    dataSource: { type: Object },
    submitFormComponent: { type: Boolean },
    resetComponent: { type: Boolean },
    currentDataValues: { type: Object },
    config: { type: Object },
  },
  data() {
    return {
      selectedItem: undefined,
      dirty: false,
      tooltipActivated: {},
    };
  },
  computed: {
    items() {
      return this.dataSource[this.config.dataSourceAttr] || [];
    },
    itemValue() {
      return [this.config.formAttribute === 'delimiter' ? 'value' : 'id'];
    },
    label() {
      return this.$t(this.config.formAttribute + 'Label');
    },
    noDataText(){
      return this.config.noDataText ?? 'noDataAutocomplete'
    }
  },
  methods: {
    emitIsValidForm() {
      this.$emit('isValid', this.$refs.form.validate());
    },
    emitIsDirty() {
      this.$emit('isDirty', { dirty: this.dirty });
    },
    setTooltipActivated(event, ref) {
			const { target } = event;
			this.$set(
				this.tooltipActivated,
				ref,
				target.offsetWidth < target.scrollWidth,
			);
		},
		isTooltipActivated(ref) {
			return this.tooltipActivated[ref];
		},
    openMenu() {
      this.$nextTick(() => {
        if (this.$refs.autocomplete && this.$refs.autocomplete.$refs.menu) {
          this.$refs.autocomplete.$refs.menu.isActive = true;
        }
      });
  },
},
  watch: {
    selectedItem(v) {
      if (v) {
        if (!this.dirty) this.dirty = true;
        if (this.config.formAttribute === 'delimiter') this.$emit('update', { delimiter: v });
        else {
          const item = this.dataSource[this.config.dataSourceAttr].find(e => e.id === v);
          const itemToEmit = [{ key: item.id, value: item.name }];
          this.$emit('update', { inputDescriptorInfo: itemToEmit });
        }
      } else this.$emit('update', null);
      this.emitIsValidForm();
    },
    submitFormComponent(v) {
      if (v) {
        this.emitIsValidForm();
        this.$nextTick(() => {
          this.emitIsDirty();
        })
      }
    },
  },
  created() {
    const value = this.currentDataValues && this.currentDataValues[this.config.formAttribute]
    if (value) this.selectedItem = Array.isArray(value) ? value[0].key : value
  }

};
</script>
<style lang="scss" scoped>
.v-list {
		max-width: 396px;
}
</style>