<template>
  <div>
    <v-alert v-for="(alert, index) in staticAlerts" :key="`static-alert-${index}`" outlined :type="alert.type"
      :icon="alert.icon" class="stepper-alert mt-4">
      {{ alert.text }}
    </v-alert>
    <Form ref="form" :fields="fields" :currentDataValues="currentValues"
      :class="staticAlerts.length > 0 ? 'mt-10' : 'mt-5'" @updatedForm="update" @isValidForm="isValidForm"
      :resetForm="resetForm" :getStatus="getStatus" primaryColor="var(--primary)">
    </Form>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import { uuid } from 'vue-uuid';

export default {
  name: "DictionaryKeyValuesForm",
  data: () => ({
    dirty: false,
    currentValues: {},
    inputName: "multipleField",
    hasError: false,
    initialValue: undefined,
  }),
  props: {
    currentDataValues: { type: Object },
    resetForm: { type: Boolean },
    dataSource: { type: Object },
    config: { type: Object },
    submitFormComponent: { type: Boolean },
    getStatus: { type: Boolean },
    globalValues: { type: Array },
  },
  methods: {
    isValidForm(value) {
      const error = !value.valid;
      this.disabled = error;
      if (this.hasError !== error) this.hasError = error;
      else this.$emit("isValidForm", { valid: !error });
    },
    update(event, fromCreated = false) {
      if (this.$refs.form) this.$refs.form.validate();
      if (!fromCreated) this.dirty = true;
      let data = event;
      if (this.hasError) {
        this.$emit("isValidForm", { valid: false });
      }
      this.$emit("dirty", this.dirty);
      this.$emit("update", data);
    },
    setCurrentValues(fromCreated = false) {
      if (this.currentDataValues && this.currentDataValues[this.inputName]) {
        this.currentValues = { [this.inputName]: this.currentDataValues[this.inputName] };

        if (fromCreated) {
          this.initialValue = this.currentDataValues[this.inputName];
        }
      } else {
        this.currentValues = { [this.inputName]: [{ uuid: uuid.v4(), value: "" }] };
      }
    },
    getFormStatus() {
      this.$emit("dirty", this.dirty);
    },
  },
  computed: {
    staticAlerts() {
      return this.config?.staticAlerts || [];
    },
    fields() {
      const { label = i18n.t("multipleColumn"), deleteModal = undefined } = this.config || {}
      return [{
        name: this.inputName,
        label: label,
        fieldType: "multipleFields",
        countLabel: true,
        withId: true,
        deleteItemModal: deleteModal,
        iconColorAddActive: "var(--primary)",
        iconColorDelete: "var(--primary)",
        validators: {
          required: { msg: i18n.t("required") },
          sameCombinationValuesSameField: { msg: i18n.t("keyRepeated") }
        },
      }];
    }
  },
  created() {
    if (this.config?.formAttribute) this.inputName = this.config.formAttribute;
    this.setCurrentValues(true);
    this.update(this.currentDataValues && this.currentDataValues[this.inputName], true);
  },
  watch: {
    currentDataValues() {
      this.setCurrentValues();
    },
    resetForm() {
      this.currentValues = { [this.inputName]: [""] }
      this.update(this.currentValues[this.inputName]);
    },
    getStatus(val) {
      if (val) this.getFormStatus();
    },
    submitFormComponent(val) {
      this.$refs.form.validate();
      if (val) {
        this.getFormStatus();
      }
    },
    hasError: {
      immediate: true,
      handler(val) {
        this.$emit("isValidForm", { valid: !val });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.aliasInput {
  padding-bottom: 10px;
}
</style>
