<template>
  <v-row class="error-wrapper" align="center" justify="center">
    <Card tile class="text-center pa-10">
      <CustomError 
          :title="traduceItem(title)"
          :subtitle="traduceItem(subtitle)"
          :text="traduceItem(text)"
          :primaryButton="canGoBack ? {text: goBackText, icon: 'mdi-reply'} : null"
          :secondaryButton="!canGoBack ? {text: goProcessHistory, icon: 'mdi-history'} : null" 
          @action="selectedAction"
      >
          <template v-slot:between>
              <div class="mb-3 error-slot" >
                  {{ traduceItem(mainText) }}
              </div>
          </template>
      </CustomError>
    </Card>

  </v-row>
</template>

<script>
import i18n from "@/plugins/i18n";
import { tools } from "../mixins/tools";
/**
 * This component shows an error message with the diferent data that receives.
 * It is usually used to show 403 or 404 errors.
 * 
 * @example ../../docs/CustomError.md
 */
export default {
  name: 'CustomErrorCard',
  props: {
    /**
     * The component's title.
     */
    title: {type:String, default: 'error.sorry'},
    /**
     * The component's subtitle. It is shown after the title, separeted with a comma and with '...' at the end.
     */
    subtitle: {type: String, default: 'error.problem'},
    /**
     * A text to be displayed under the error number / icon.
     * It can be used to give some more information about the error.
     */
    text: {type: String, default: 'error.noWorries'},
    /**
     * Main text to be shown
     */
    mainText: {type: [Number, String], default: 'error.canNotTellWhy'},
    /**
     * Property that indicates if the action buttons are shown or not.
     * Action buttons includes a Go back and a Go home buttons.
     */
  },
  mixins: [tools],
  computed: {
    canGoBack() {
      return window.history ? window.history.length > 2 : false;
    },
    goBackText() {
      return i18n.t('goBack');
    },
    goProcessHistory() {
      return i18n.t('goProcessHistory');
    }
  },
  methods: {
    selectedAction(action) {
      if (action === this.goBackText) this.$router && this.$router.go(-2)
      else this.$router.push({ name: 'ProcessHistory' })
    }
  }
}
</script>

<style lang="scss" scoped>
.error-wrapper {
  min-height: 100%;
  .error-slot {
      color: var(--secondary);
      font-size: 50px;
      font-weight: 400;
      line-height: 59px;
    }
}
</style>
