<template >
  <v-breadcrumbs class="px-0 pt-0" :class="$vuetify.breakpoint.smAndDown ? 'pb-4' : 'pb-5'" :items="items" ref="headerBreadCrums">
    <template v-slot:divider>
      <v-icon>mdi-chevron-right</v-icon>
    </template>
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        :to="item.index !== items.length  && item.to || '' "
        exact
        :disabled="item.disabled"
      >
        <v-icon v-if="item.text === 'home'" :color="activeIcon ? 'var(--primary)' : null">{{item.icon}}</v-icon>
        <template v-else>
          <span :class="item.index === items.length && 'lastItem'">{{ item.text }}</span>
          <div v-if="item.subtext" :class="item.subclass">{{ item.subtext }}</div>
          <span v-if="item.index === items.length - 1 && dataFromView">
            ({{dataFromView}})
          </span>
        </template>
      </v-breadcrumbs-item>
    </template> 
  </v-breadcrumbs>
</template>


<script>
import i18n from "@/plugins/i18n";
import {  mapState } from "vuex";
export default {
  name: "Breadcrumbs",
  props: ["dataFromView"],
  data: () => ({
    items: [],
    activeIcon: false,
  }),
  methods: {
    getItems(params){
      this.items = []
      const pathItems = this.$router.history.current.matched;
      pathItems.forEach((element, index) => {
        if (element.name)
          this.items.push({
            text: i18n.t(element.name || element.meta.name),
            index: index,
            to:'/'+element.path.split('/').filter(Boolean)[0]
          });
      });

      if (this.items.length > 1) this.items.at(-1).text = this.dataView?.name
      else if (this.dataView?.name) this.items.push({
          text: this.dataView?.name,
          index: this.items.length+1
        })
      
      if (params.item) {
        const itemToSetName = this.items.findIndex(item => item.text === params.itemToSetName);
        const element = params.item;
        this.items[itemToSetName].text = `${this.items[this.items.length - 1].text}`;
        this.items[itemToSetName].subtext = `(${element.name})`;
        this.items[itemToSetName].subclass = 'font-italic pl-1';
      }
      if (params.itemToNavigate) {
        const itemToNavigate = this.items.findIndex(item => item.text === params.itemToNavigate);
        const url = `${pathItems[itemToNavigate - 1].path}${pathItems[itemToNavigate].meta.breadcrumbs}${this.$router.history.current.params.query ? "?" + this.serialize(this.$router.history.current.params.query) : ""}`;
        this.items[itemToNavigate].to = url;
      }
      if (params.view) {
        const view = params.view;
        const tab = params.tab;
        this.items.push({
          text: tab ? tab : i18n.t(view),
          index: this.items.length
        });
      }

      this.activeIcon = this.$router.history.current.name === 'ProcessHistory' ? true : false;
    },
    serialize(obj) {
      const str = [];
      for (const p in obj)
        if (Object.prototype.hasOwnProperty.call(obj, p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    }
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler() {
        this.getItems(this.$router.history.current.params);
      },
    },
    userLogged(){
      this.items = []
      this.getItems(this.$router.history.current.params)
    },
    dataView(){
      this.getItems(this.$router.history.current.params)
    }
  },
  computed: {
    ...mapState([
    "dataView",
    "userLogged"
    ]),
  },
};
</script>

<style lang="scss" scoped>
 .v-breadcrumbs::v-deep li {
  font-weight: 400;
  font-size: 12px;
  .lastItem {
    font-weight: 500;
    font-size: 14px;
  }
}

</style>