export const projectTheme = {
    name: "trafis-ui",
    properties: {
        "--primary": "#9C40C7",
        "--primaryRGB": "25, 115, 184",
        "--red": "#CC0000",
        "--white": "#FFFFFF",
        "--secondary": "#117E8A",
        "--tertiary":"#2F8E98",
        "--light": "#D2A903",
        "--font": "#333",
        "--gray": "#555555",
        "--ligthGray":'#999',
        "--logo": 'url("/img/LOGO_TRAFIS_PURPLE.svg")',
        "--iconGoogle": 'url("/img/googleIcon.svg")',
        "--loginBottomDark": 'url("/img/BG1-YELLOW.svg")',
        "--loginBottomMedium": 'url("/img/BG2-YELLOW.svg")',
        "--loginBottomLight": 'url("/img/BG3-YELLOW.svg")',
        "--faceImg": 'url("/img/faceYellow.svg")',
        "--faceImgDark": 'url("/img/face_yellow_dark.svg")',
        "--authBgDark": 'url("/img/DARK-YELLOW-SQUARES.svg")',
        "--authBgMedium": 'url("/img/bg_Trafis.jpg")',
        "--authBgLight": 'url("/img/LIGHT-YELLOW-SQUARES.svg")',
    }
};



