import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import es from 'vuetify/lib/locale/es'

Vue.use(Vuetify);

const customThemeColors = {
  error: '#CC0000',
  info: '#4EA7DD',
}

export default new Vuetify({
    lang: {
        locales: { es }
    },
    theme: {
      themes : {
        light: customThemeColors,
        dark: customThemeColors,
      }
    },
    // theme: {
    //     dark: localStorage.getItem('appearanceMode') === 'dark'
    // }
});
