<template>
  <div v-if="$vuetify.theme.themes.name === 'default'">
    <FreshdeskButton class="freshdesk-button"/>
    <div class="auth-wrp d-flex justify-center align-center" :style="{ backgroundImage: envTheme['--authBgMedium']}">
      <v-card tile class="pa-5 login-card">
        <v-card-title class="pa-0 mb-5">
          <div class="auth-logo" :style="{ backgroundImage: envTheme['--logo']}" ></div>
        </v-card-title>

        <router-view></router-view>
      </v-card>
    </div>
  </div>
  <div v-else class="auth-wrp d-flex justify-center align-center loginContainer">
    <v-card tile class="pa-5 login-card">
      <v-card-title class="pa-0 mb-5">
        <div class="auth-logo" :style="{ backgroundImage: envTheme['--logo']}" ></div>
      </v-card-title>
      <router-view></router-view>
    </v-card>
  </div>
</template>


<script>
import { mapState } from "vuex";
import FreshdeskButton from "@/components/FreshdeskButton.vue";

export default {
  name: "LayoutAuth",
  computed: {
    ...mapState(["envTheme"])
  },
  components: {
    FreshdeskButton,
  }
};
</script>

<style lang="scss" scoped>
.auth-wrp {
  background-size: cover;
  background-color: var(--bgGray);
  min-height: 100vh;
  background-position: center;
  .v-card {
    width: 300px;
    background: rgba(255, 255, 255, 0.8);
    &.theme--dark {
      background: rgba(34, 34, 34, 0.8);
    }
  }
}

.auth-logo {
  width: 100%;
  height: 40px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

// Parallax effect
.parallax-container {
  width: 100%;
  overflow: hidden;
}

.parallax-element {
  margin-left: -5%;
  margin-top: -15vh;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 110%;
  height: 120vh;
}
.parallax-squares-element {
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 60%;
  height: 60%;
  display: block;
}
.freshdesk-button {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 100;
}
</style>
