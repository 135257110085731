<template>
	<section class="mt-1">
		<Card v-for="(v, i) in [5, 4, 3, 2, 1]" :key="i" class="mb-5">
			<v-skeleton-loader class="d-flex flex-wrap align-center ga-3 px-6 py-6">
				<span
					:style="{
						width: v % 2 !== 0 ? '191px' : '143px',
						'background-color': `rgba(153, 153, 153, 0.${v})`,
					}"></span>
			</v-skeleton-loader>
		</Card>
	</section>
</template>

<script>
	export default {
		name: "ListSkeleton",
	};
</script>

<style scoped>
	span {
		height: 20px;
		border-radius: 5px;
	}
</style>
