<template>
  <div class="data">
    <slot name="breadcrumbsWrp" :item="dataForTesting"></slot>
    {{actualView.name}}
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Data",
  data: () => ({
    dataForTesting: 'This is a test for breadcrumb'

  }),
  computed: {
    ...mapState([
      "actualView",
    ])
  },
};
</script>

<style lang="scss" scoped>
</style>
