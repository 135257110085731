<template>
	<v-skeleton-loader class="d-flex menu-tab-loader align-center mt-4"
  :class="$vuetify.breakpoint.smAndDown ? 'mb-4' : 'mb-5'">
		<span class="v-skeleton-loader__bone"></span>
		<span
			class="v-skeleton-loader__bone"></span>
		<span
			class="v-skeleton-loader__bone"></span>
	</v-skeleton-loader> 
</template>

<script>
	export default {
		name: "TabsSkeleton",
	};
</script>

<style scoped>
	.v-skeleton-loader__bone {
		height: 16px;
		border-radius: 20px;
		background-color: #9999997e;
		width: 130px;
	}

  .menu-tab-loader {
    border-bottom: 1px solid var(--borderGray);
    height: 50px;
    gap: 27px;
  }
</style>
