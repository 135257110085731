<template>
	<div class="container" ref="headerCards">
		<div v-for="(card, index) in cards" :key="index" class="cards-detail">
			<Card class="d-flex px-3 py-3 info-card">
				<v-icon
					:color="envTheme['--secondary']"
					class="header-icon"
					:class="card.name"
					>{{ card.icon }}</v-icon
				>
				<CardContent class="card-content-text card-content">
					<div class="card-header">{{ traduceItem(card.name) }}</div>
					<v-tooltip
						bottom
						:disabled="!activatedTooltip['info-card-' + card.name]"
						:max-width="300">
						<template v-slot:activator="{ on }">
							<span v-on="on" @mouseover="setCardTooltip($event, card)" class="info-span">
								{{ setCardValue(card) }}
							</span>
						</template>
						<span>
							{{ setCardValue(card) }}
						</span>
					</v-tooltip>
				</CardContent>
			</Card>
		</div>
	</div>
</template>

<script>
	import i18n from "@/plugins/i18n";
	import { mapState } from "vuex";
	import { tools } from "@/mixins/tools";

	export default {
		name: "InformationCards",
		mixins: [tools],
		props: {
			cards: { type: Array },
			dataComponent: { type: Object },
		},
		data: () => ({
			activatedTooltip: {},
		}),
		computed: {
			...mapState(["envTheme"]),
		},
		methods: {
			setCardValue(card) {
				let value = this.dataComponent[card.name]
					? this.dataComponent[card.name][card.nestedValue] || this.dataComponent[card.name]
					: "--";
				if (card.translationResponse && value !== "--") value = i18n.t(value);
				return value;
			},
			setCardTooltip(e, card) {
				this.$nextTick(() => {
					this.$set(
						this.activatedTooltip,
						"info-card-" + card.name,
						e.target.scrollWidth > e.target.offsetWidth,
					);
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.header-icon::after,
	.header-icon::before {
		font-size: 46px;
	}
	.defaultDecimalSeparator::before,
	.defaultDecimalSeparator::before {
		background: var(--secondary);
		color: var(--bgGray);
		border-radius: 50%;
	}
	.container {
		padding: 20px 10px;
		margin: -20px -10px;
		overflow-x: auto;
		display: grid;
		grid-template-columns: repeat(3, minmax(260px, 1fr));
		gap: 20px;
	}
	.info-card {
		min-width: 260px;
		white-space: nowrap;
	}
	.card-content,
	.info-span {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
    display: block;
	}
	.cards-detail {
		.card-content-text {
			padding: 0 6px !important;
			color: var(--secondary) !important;
			font-weight: 500;
			.card-header {
				font-weight: 400;
				color: var(--fontColor);
				padding: 0;
				font-size: 16px;
			}
		}
	}
	@media (max-width: 1120px) {
		.container {
			grid-template-columns: repeat(2, minmax(260px, 1fr));
		}
	}
</style>
