<template>
  <div class="mt-5 variables-container">
    <v-alert outlined type="info" color="info" icon="mdi-information">{{ $t(infoText) }}</v-alert>
    <template v-if="fieldInputArray.length > 0">
      <v-form ref="formVariable" @submit.prevent>
        <v-text-field v-for="input in fieldInputArray" :key="input.name" :ref="input.name" :name="input.name" :label="input.label" v-model="input.value" :autofocus="true"
          :type="'text'" @input="update(input.name, $event)" :rules="[...input.validations]" class="input-variable"
          @update:error="getError($event)" @change="onChange"></v-text-field>
      </v-form>
    </template>
    <div class="no-variables" v-else>
      <span>{{ $t(noVariables) }}</span>
    </div>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import { mapState } from "vuex";


export default {
  name: "InputVariablesArrayForm",
  data: () => ({
    dirty: false,
    infoText: 'alertInfoInputVariablesArrayFormText',
    noVariables: 'noVariables',
    hasError: false,
  }),
  props: {
    currentDataValues: { type: Object },
    previousStepData: { type: Object },
    submitFormComponent: { type: Boolean },
    resetComponent: { type: Boolean },
  },
  watch: {
    dirty(val) {
      this.$emit('dirty', val);
    },
    submitFormComponent(val) {
      if (!val) return;
      this.$emit('isValid', this.validate());
    },
    resetComponent(val) {
      if (!val) return;
      this.fieldInputArray.forEach((input, index) => {
        this.$refs[`variable${index}`][0].reset();
      });
      this.dirty = false;
    },
    'fieldInputArray.length':{
      handler(val) {
        const valueToEmit = val ? this.getValue() : null;
        this.$emit('update', valueToEmit)
      },
      immediate: true
    }
  },
  computed: {
    ...mapState([
      "outputDefinitionsData",
    ]),
    outputDefinitionData(){
      let idDescriptors = [];
      if (this.previousStepData && this.previousStepData.outputDescriptorInfo) {
        idDescriptors = this.previousStepData.outputDescriptorInfo.map(descriptor => descriptor.key);
        return this.outputDefinitionsData
        .filter(output => idDescriptors.findIndex(descriptor => descriptor === output.id) !== -1)
      }
      return []
    },
    fieldInputArray() {

      if(!this.outputDefinitionData.length)return []
      const inputMap = new Map()
      return this.outputDefinitionData
        .flatMap(output => [...this.getVariablesByColumns(output.columns || []), ...this.getVariablesBySplitConfiguration(output.splitConfiguration)])
        .filter(output => {
          if (inputMap.has(output.name)) return false;
          inputMap.set(output.name, true);
          return true;
        });
    }
  },
  methods: {
    isVariable(item){
      return !!item && item.option === "VARIABLE"
    },
    validate() {
      return this.$refs?.formVariable ? this.$refs.formVariable.validate() : true;
    },
    validateField(name) {
      return this.$refs[name][0].validate()
    },
    onChange() {
      this.dirty = true;
    },
    getVariablesBySplitConfiguration(splitConfiguration) {
      if (!splitConfiguration || !splitConfiguration.linesReferences) return [];
      return this.getVariablesByColumns(splitConfiguration.linesReferences
        .filter(line => line.columnReferences)
        .flatMap(line => line.columnReferences));
    },
    setVariablesInColumns(columns) {
      const variables = [];
      const setVariablesArr = (item) => {
        if (!!item && !variables.some(element => element.value === item.value)) {
          variables.push({ ...item })
        }
      }
      const getVariable = (column) => {
        if (!column || typeof column !== 'object') {
          return;
        }
        if(Array.isArray(column)){
          column.forEach(item => getVariable(item))
          return;
        }
        if (typeof column === 'object') {
          Object.keys(column).forEach(key => {
            if (this.isVariable(column[key])) {
              setVariablesArr(column[key])
            } else {
              getVariable(column[key])
            }
          })
        }
      }
      columns.forEach(column => getVariable(column))
      return variables
    },
    getVariablesByColumns(arrayColumns) {
      const variables = this.setVariablesInColumns(arrayColumns)
      return variables.map((columnReference)=>{
        const columnReferenceValue = columnReference.value
        return {
          name: columnReferenceValue,
          label: columnReferenceValue,
          validations: [this.required({ msg: i18n.t("required") })],
          value: this.currentDataValues?.variables.find(({key}) => key === columnReferenceValue)?.value || null
        }
      })
    },
    getError(error) {
      if (this.hasError !== error) this.hasError = error;
      else this.$emit("isValidForm", { valid: !error });
    },
    update(name, event) {
      const isValid = this.validateField(name);
      const valueToEmit = isValid && event.length ? this.getValue() : null;
      this.$emit('isValid', isValid);
      this.$emit('update', valueToEmit);
    },
    getValue() {
      return { variables: this.fieldInputArray.map(input => { return { key: input.name, value: input.value } }) };
    },

    required({ msg }) {
      return v => {
        if (v || v === 0)
          return Array.isArray(v) && v.length === 0 ? msg : true;
        return msg;
      };
    },
  }
};
</script>

<style lang="scss" scoped>
.no-variables {
  display: flex;
  font-size: 20px;
  font-weight: 500;
  color: #999999;
  justify-content: center;
}

.variables-container {
  display: flex;
  gap: 20px;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 20px;
}
</style>