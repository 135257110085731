<template>
  <section class="expansible-alert-wrapper">
    <v-alert
      outlined
      :type="alert.type"
      :icon="alert.icon"
      class="expansible-alert"
    >
      {{ $t(alert.text, {attr: $t(alertAttr)}) }}
      <template v-slot:append>
        <v-icon
          v-if="hasExpansible"
          :color="alert.type"
          @click="toggleExpand"
        >
          {{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
      </template>
    </v-alert>
    <v-expand-transition>
      <v-card v-if="hasExpansible && isExpanded" class="dropDownAlert" :class="{ open: isExpanded }">
        <v-card-text>
          <ol v-if="alert.expansibleInfo">
            <li v-for="info in alert.expansibleInfo" :key="info" v-html="$t(info)">
            </li>
          </ol>
          <template v-if="alert.expansibleExample">
            <p class="expansible-info-example">{{ $t('example') }}:</p>
            <p class="expansible-info-example">{{ $t(alert.expansibleExample)}}</p>
          </template>
        </v-card-text>
      </v-card>
    </v-expand-transition>
  </section>
</template>

<script>
export default {
  name: 'ExpansibleAlert',
  props: {
    alert: {
      type: Object,
      required: true,
    },
    alertAttr: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isExpanded: false,
    }
  },
  computed: {
    hasExpansible() {
      return Boolean(this.alert?.expansibleInfo || this.alert?.expansibleExample);
    },
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    }
  }
}
</script>

<style scoped lang="scss">
.expansible-alert-wrapper {
  position: relative;
}
.expansible-alert {
  margin-top: 20px;
  position: relative;
}
v-icon {
  cursor: pointer;
}
.dropDownAlert.v-card {
  position: absolute;
  width: 100%;
  padding: 20px;
  z-index: 3;
  & > .v-card__text {
    color: #333;
    padding: 0;
  }
  
  ol {
    list-style: inital;
    padding-left: 20px !important;
    & li:not(:last-of-type) {
      margin-bottom: 12px;
    }
  }
  .expansible-info-example {
    margin: 0;
    &:first-of-type {
      padding-top: 12px;
    }
    &:last-child {
      font-weight: 500;
    }
  }
}
</style>