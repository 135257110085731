<template>
  <v-dialog :value="dictionaryToImport" :width="800" v-click-outside="cancel" content-class="containerStepper"
    scrollable >
    <v-card>
      <v-card-title v-if="importConfig?.title">
        <div>{{ importConfig.title }}</div>
        <div>
          <v-btn rounded outlined v-if="importConfig.downloadButton" @click="downloadDictionaryTemplate()">
            <v-icon left>{{ importConfig.downloadButton.icon }}</v-icon>
            {{ importConfig.downloadButton.text }}
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text class="text-content d-flex">
        <div class="left-stepper" min-height="380">
          <v-stepper vertical flat>
            <div class="step-item">
              <v-stepper-step :step="1">
                {{ traduceItem("selectFile") }}
              </v-stepper-step>
            </div>
          </v-stepper>
        </div>
        <div class="right-stepper" v-if="dictionaryToImport">
          <v-stepper vertical flat :height="'100%'" :min-height="'100%'">
            <v-stepper-step :step="1">
              {{ traduceItem("selectFile") }}
            </v-stepper-step>
            <v-stepper-content :step="1" class="pl-5" :height="'100%'">
              <ImportFile :submitFormComponent="submitFormComponent" :resetComponent="resetComponent"
                @isValid="isValid = $event" @dirty="dirty = $event" @update="updateValues($event)" :config="allowedExtensions"  />
            </v-stepper-content>
          </v-stepper>
        </div>
      </v-card-text>
      <v-card-actions v-if="dictionaryToImport">
        <v-btn rounded outlined v-if="importConfig.secondaryButton" @click="cancel('buttonCancel')">
          <v-icon left>{{ importConfig.secondaryButton.icon }}</v-icon>
          {{ importConfig.secondaryButton.text }}
        </v-btn>
        <v-btn v-if="importConfig.primaryButton" @click="onSubmit()" color="primary" rounded depressed>
          <v-icon left>{{ importConfig.primaryButton.icon }}</v-icon>
          {{ importConfig.primaryButton.text }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { tools } from "@/mixins/tools";
import { firebaseTools } from "@/mixins/firebase-tools.js";
import ImportFile from "../stepperComponents/ImportFile.vue";
import i18n from "@/plugins/i18n";
import { getDownloadURL } from "firebase/storage";

export default {
  name: "StepperImportDictionary",
  mixins: [tools, firebaseTools],
  components: {
    ImportFile,
  },
  data: () => ({
    dirty: false,
    isValid: true,
    currentDataValues: undefined,
    submitFormComponent: false,
    resetComponent: false,
    loading: false,
  }),
  computed: {
    ...mapState(["dictionaryToImport", "userLogged", "actualGroup", "actualView"]),
    allowedExtensions() {
      return this.importConfig?.allowedFileTypes ? { allowedExtensionFiles: this.importConfig.allowedFileTypes } : {};
    },
    importConfig() {
      return this.dictionaryToImport?.config;
    },
  },
  methods: {
    ...mapActions(["setItemToImportDictionary", "setShowSnackBar", "setLoadingData"]),
    cancel(e) {
      if (
        !(
          (e.target && e.target.classList.contains("v-overlay__scrim")) ||
          e === "buttonCancel"
        ) ||
        !this.dictionaryToImport
      )
        return;
      const dirty = this.dirty;

      this.executeAction({
        action: "cancel",
        section: "ImportDictionary",
        dirty,
      });
    },
    updateValues(event) {
      this.submitFormComponent = false;
      this.currentDataValues = { ...event };
    },
    downloadDictionaryTemplate() {
      this.setShowSnackBar({
        color: 'info',
        msg: i18n.t('downloadingFile'),
        icon: "mdi-information",
      })
      this.loading = true;
      const fileRef = this.createStorageReference('templates/trafis_dictionary_template.csv');
      // Get the download URL
      getDownloadURL(fileRef)
        .then((url) => {
          this.downloadFile(
            url,
            "get",
            'trafis_dictionary_template.csv',
            null
          ).then(() => {
            this.loading = false;
          });
        })
        .catch(() => {
          this.setShowSnackBar({
            color: 'error',
            msg: i18n.t('errorDownloadFile'),
            icon: "mdi-alert-circle",
          })
          this.loading = false;
        });
    },
    onSubmit() {
      this.submitFormComponent = true;
      let dataToSubmit = null;

      this.$nextTick(() => {
        this.submitFormComponent = false;
        if (this.isValid) {
          dataToSubmit = { ...this.currentDataValues };
          this.startImportDictionary(dataToSubmit.selectedFile)
        }
      });
    },
    async startImportDictionary(selectedFile) {
      let params = new FormData();
      if (this.importConfig && this.importConfig.data && this.importConfig.data.dictionaryId) {
        params.append("dictionaryId", this.importConfig.data.dictionaryId);
      }
      params.append("lang", this.userLogged.lang);
      params.append("file", selectedFile);
      params.append("userGroup", this.actualGroup?.id);
      this.setShowSnackBar({
        color: "info",
        icon: "mdi-information",
        msg: i18n.t("uploadedImport", { element: selectedFile.name }),
      });
      this.setLoadingData(true);
      this.setItemToImportDictionary(false);
      this.httpRequest("post", [window.PROJECT_ENV.BASE_URL_SENDER + "import/dictionary", params], false)
        .then(async (res) => {
          if (res) {
            await this.setShowSnackBar(false)
            const error = res.level === "FAIL";
            this.setShowSnackBar({
              color: error ? "error" : "success",
              icon: error ? 'mdi-alert-circle' : "mdi-check-circle",
              msg: error ? res.msg : i18n.t("successfulImport", { element: selectedFile.name }),
            });
          }
        })
        .catch((error) => {
          this.handleHttpStatus(error);
        }).finally(() => {
          this.setLoadingData(false);
          this.loading = false;
        });
    },
  },
  watch: {
    dictionaryToImport(v) {
      if (!v) {
        this.currentDataValues = undefined;
        this.resetComponent = true;
        this.isValid = true;
        this.isDirty = false;
        return;
      }
      this.resetComponent = false;
    },
    loading(v) {
      this.setLoadingData(v);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog__content {
  ::v-deep {
    .containerStepper {
      border-radius: 20px;
      min-height: 95vh;
    }
  }

  .v-card__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    padding: 20px;
  }

  .v-card__actions {
    padding: 20px;
  }
}

.left-stepper,
.right-stepper {
  ::v-deep {

    .v-stepper__step,
    .v-stepper .step-item .v-stepper__step {
      padding: 0px 20px 0px 0px;

      .v-stepper__label {
        font-size: 16px;
        font-weight: 500;
      }

      &:not(.v-stepper__step--active) .v-stepper__label {
        font-weight: 400;
      }
    }
  }
}

.left-stepper {
  width: 45%;
  padding-right: 5px;
  border-right: 1px solid var(--borderGray);

  .v-stepper {
    /* heigth - header - footer */
    height: calc(95vh - 72px - 76px);
    scrollbar-gutter: auto;
    overflow: auto;
  }

  .v-stepper .v-stepper__step {
    cursor: pointer;
    padding-right: 20px;
    align-items: baseline;
  }

  .step-item {
    &:not(:last-child) {
      ::v-deep {
        .v-stepper__content {
          margin-left: 10px !important;
          border-left: 1px solid var(--borderGray) !important;
          min-height: 50px;
        }
      }
    }

    &:not(:first-child) {
      ::v-deep .v-stepper__step__step {
        margin-top: 20px;
      }
    }

    ::v-deep {
      .v-stepper__step {
        cursor: pointer;

        .v-stepper__step__step {
          margin-bottom: 20px;
        }

        .v-stepper__label {
          font-size: 16px;
          font-weight: 400;
        }
      }

      .v-stepper__content[complete].step-resume {
        .v-stepper__wrapper {
          height: auto !important;
        }
      }
    }
  }
}

.right-stepper {
  overflow: auto;
  width: 55%;

  .v-stepper,
  .v-stepper__content {
    height: 100%;
  }

  .v-stepper__step {
    padding-left: 20px;

    ::v-deep .v-stepper__label {
      font-weight: 500;
    }
  }
}

.v-application--is-ltr .v-stepper--vertical .v-stepper__content {
  margin: 0;
  padding: 0;
}

.contenedor {
  width: 100%;
}

::v-deep {
  .v-card__text.text-content {
    padding: 0 20px;
    /* heigth - header - footer */
    height: calc(95vh - 72px - 76px);
    overflow-y: hidden;
  }

  .v-stepper__wrapper {
    overflow: visible;
    height: 100% !important;

  }

  .v-stepper__step {

    &.v-stepper__step--active .v-stepper__label,
    &.v-stepper__step--complete .v-stepper__label {
      color: var(--fontColor);
    }

    &.v-stepper__step--inactive:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) {
      .v-stepper__step__step {
        background-color: var(--lightGray);
      }

      .v-stepper__label {
        color: var(--fontColorTerciary);
      }
    }
  }

  .v-chip.v-size--small {
    font-size: 14px;
  }
}
</style>
