import store from "@/store";
import { authentication } from "@/mixins/authentication";

export default {
  install(Vue) {
    // oneOfThem param check if we want to know if user logged has one of the roles of a condition.
    Vue.prototype.$hasRoles = (roles, oneOfThem) => {
      return this.checkRoles(roles, oneOfThem);
    };
  },
  checkRoles(roles, oneOfThem) {
    const userRoles = store.state.userLogged 
      ? store.state.userLogged.roles
      : JSON.parse(localStorage.getItem("userLogged")) ? JSON.parse(localStorage.getItem("userLogged")).roles : [];
    if (typeof roles === "string" && !userRoles.includes(roles)) return false;
    else if (Array.isArray(roles)) {
      if (oneOfThem && !roles.filter((role) => userRoles.includes(role)).length)
        return false;
      else if (
        !oneOfThem &&
        authentication.methods.rolesDiff(roles, userRoles).length
      )
        return false;
    }
    return true;
  },
};
