<template>
	<button @click="openFreshdesk">
		<v-icon color="#fff">mdi-face-agent</v-icon>
		<span class="ml-1">{{ buttonTitle }}</span>
	</button>
</template>

<script>
	import { mapState } from "vuex";
	import i18n from "@/plugins/i18n";

	export default {
		methods: {
			openFreshdesk() {
				const filledFields = {
					name: this.userLogged?.displayName || "",
					email: this.userLogged?.email || "",
					//Si en un campo custom de freshdesk se llama "campo", automaticamente lo tienes que meter en el objeto custom_fields y llamarlo "cf_campo"
					custom_fields: {
						cf_url: window.location.href,
						cf_entorno:
							window.PROJECT_ENV.FRESHDESK_ENVIRONMENT || "Entorno desconocido",
						cf_application:
							window.PROJECT_ENV.FRESHDESK_APP || "Aplicación desconocida",
						cf_empresa:
							window.PROJECT_ENV.FRESHDESK_COMPANY || "Empresa desconocida",
					},
				};

				window.FreshworksWidget("prefill", "ticketForm", filledFields);
				// Prefill name and mail. API REFERENCE HERE --> https://developers.freshdesk.com/widget-api/#prefill-fields

				window.FreshworksWidget("open", "ticketForm");

				this.setBackgroundColor();
			},
			setBackgroundColor() {
				const attemptToSetBackground = () => {
					const containerElement = document.body.querySelector("#widget-frame");
					if (
						containerElement &&
						containerElement.contentWindow &&
						containerElement.contentWindow.document
					) {
						const targetElement =
							containerElement.contentWindow.document.body.querySelector(
								`[class*="WidgetHeader__Wrapper"]`,
							);
						if (targetElement) {
							targetElement.style.background = this.envTheme["--primary"];
							return;
						}
					}
					setTimeout(attemptToSetBackground, 1);
				};

				attemptToSetBackground();
			},
		},
		computed: {
			...mapState(["userLogged", "envTheme"]),
			buttonTitle() {
				return i18n.t("support");
			},
			hiddenFields() {
				return [
					"custom_fields.cf_url",
					"custom_fields.cf_entorno",
					"custom_fields.cf_application",
					"custom_fields.cf_empresa",
				];
			},
		},
		created() {
			window.fwSettings.locale = this.userLogged?.lang || "es";

			window.FreshworksWidget("hide", "launcher");

			window.FreshworksWidget("hide", "ticketForm", this.hiddenFields);
		},
	};
</script>

<style scoped>
	button {
		color: #fff;
		border-radius: 24px;
		padding: 0 16px;
		font-size: 14px;
		height: 36px;
		width: fit-content;
		display: flex;
		align-items: center;
		background: #028484;
	}
</style>
