<template>
  <section @keydown.shift.tab.prevent="handleShiftTab">
    <v-form ref="radioForm" @submit.prevent>
      <v-radio-group
        v-model="radioForm"
        mandatory
        column
        hide-details
        class="radio-group mt-5"
        @change="onChange"
        @input="updateForm"
        @update="updateForm"
      >
        <v-radio
          v-for="option in radioOptions"
          :key="option"
          :label="$t(option)"
          :value="option"
          @keydown.enter.prevent="handleEnter"
        >
        </v-radio>
      </v-radio-group>
    </v-form>
    <MultipleSelection
      v-if="radioForm === 'withFilters'"
      key="multipleSelectionWithRadio"
      v-bind="$props"
      @dirty="dirty = $event"
      @isValid="isValid = $event"
      @update="updateForm"
    ></MultipleSelection>
  </section>
</template>

<script>
import MultipleSelection from "./MultipleSelection.vue";

export default {
  name: "WithOrWithoutFilters",
  components: { MultipleSelection },
  props: {
    config: { type: Object },
    currentDataValues: { type: Object },
    submitFormComponent: { type: Boolean },
    resetComponent: { type: Boolean },
    previousStepData: { type: Object },
  },
  data: () => ({
    dirty: false,
    isValid: false,
    radioOptions: ["withoutFilters", "withFilters"],
    radioForm: undefined,
  }),
  created() {
    const { selectedFilterNames } = this.currentDataValues || {};
    if (selectedFilterNames) this.radioForm = this.radioOptions[1];
    else this.radioForm = this.radioOptions[0];

    this.updateForm(this.currentDataValues)

  },
  watch: {
    dirty(val) {
      this.$emit("dirty", val);
    },
    isValid(val) {
      this.$emit("isValid", val);
    },
    submitFormComponent(val) {
      if (val) this.isValid = this.validateInput();
    },
    resetComponent(val) {
      if (val) {
        this.radioForm = undefined;
        this.dirty = false;
      }
    },
    radioForm() {
      this.dirty = true;
      this.$emit("update", null);
    },
  },
  methods: {
    validateInput() {
      return this.$refs.radioForm.validate();
    },
    onChange() {
      this.dirty = true;
    },
    handleShiftTab() {
      this.$nextTick(() => {
        const withFilter = this.$el.querySelector('input[value="withFilters"]');
        if (withFilter) {
          withFilter.focus();
        }
      });
    },
    focusSelectedRadioButton() {
      this.$nextTick(() => {
        const radioButton = this.$el.querySelector(".v-radio input:checked");
        if (radioButton) {
          radioButton.focus();
        }
      });
    },
    handleEnter(event) {
      if (event.key === "Enter" || event.type === "click") {
        event.preventDefault();
      }
    },
    updateForm(e) {
      this.$emit("update", e);
    },
  },
  mounted() {
    this.focusSelectedRadioButton();
  },
};
</script>
<style lang="scss" scoped>
.radio-group.v-input--selection-controls {
  ::v-deep {
    .v-radio {
      > .v-label,
      .v-icon {
        color: var(--darkGray);
      }
      > .v-label {
        font-size: 16px;
      }
    }
  }
}
</style>
