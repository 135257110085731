var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-form',{ref:"loginForm",on:{"submit":function($event){$event.preventDefault();return _vm.doLogin.apply(null, arguments)}}},[_c('v-card-text',{staticClass:"pa-0 mb-2"},[_c('v-text-field',{attrs:{"placeholder":_vm.traduceItem('user'),"name":"login","id":"login","rules":[_vm.rules.required]},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}}),_c('v-text-field',{attrs:{"placeholder":_vm.traduceItem('password'),"type":"password","id":"password","name":"password","rules":[_vm.rules.required],"autocomplete":"off"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),(_vm.errorMessage)?_c('div',{staticClass:"v-messages error--text"},[_vm._v(" "+_vm._s(_vm.traduceItem(_vm.errorMessage))+" ")]):_vm._e(),_c('div',{staticClass:"app-version mt-2 text-center"},[_vm._v(" v"+_vm._s(_vm.$appConfig.CONFIG.VERSION)+" ")])],1),_c('v-card-actions',[_c('v-btn',{staticClass:"mt-3",style:({ color: 'var(--white)' }),attrs:{"depressed":"","rounded":"","color":"var(--primary)","type":"submit","loading":_vm.loading,"disabled":_vm.loading}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-import")]),_c('span',[_vm._v(_vm._s(_vm.traduceItem("login")))])],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"mt-3",style:({
        color: '#555555',
        backgroundColor: 'var(--bgSecondary)',
        border: '1px solid var(--borderGray)',
      }),attrs:{"depressed":"","rounded":"","color":"var(--primary)","type":"submit"},on:{"click":_vm.loginWithGoogle}},[_c('div',{staticClass:"logo",style:({
          'background-image': _vm.envTheme['--iconGoogle'],
        })}),_c('span',{staticClass:"label-login-google"},[_vm._v(_vm._s(_vm.traduceItem("loginWithGoogle")))])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }