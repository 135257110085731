<template>
  <div>
    <Card class="pr-1 ma-20 mt-2">
      <CardContent>
        <div class="d-flex">
          <v-col cols="4" class="col1-container py-6" :style="{ 'max-height': `calc(100vh - ${heigthColumn}px)` }">

            <ListViewSkeleton v-if="loadingPage || loadingParent" />
            <div v-else class="d-flex align-center list pl-6 py-2" :class="{
              selectedItem:
                selectedItem && ((!actualView.isHeaderRow && selectedItem.name === listItem.name) ||
                  (actualView.isHeaderRow && selectedItem.id === listItem.id)),
            }" v-for="(listItem, index) in dataComponent" :key="index" @click="
                selectedItem = listItem;
              setData();
              ">
              <v-icon v-if="!actualView.isHeaderRow" class="mr-2 icon-color" :color="'var(--gray)'">{{ actualView.icon
                }}
              </v-icon>
              <div class="status">
                <div class="statusIcon" :class="listItem.status">
                  <v-icon v-if="listItem.status === 'FINISHED'">
                    mdi-check-circle
                  </v-icon>
                  <v-icon v-else-if="listItem.status === 'FAIL'">
                    mdi-alert-circle
                  </v-icon>
                  <v-icon v-else-if="listItem.status === 'RUNNING'">
                    mdi-cached
                  </v-icon>
                </div>
              </div>

              <div v-if="!actualView.isHeaderRow">
                {{ listItem.name }}
              </div>
              <div class="pl-4" v-else>
                {{ listItem.inputFileName }} <i>({{ listItem.date }})</i>
              </div>
            </div>
          </v-col>
          <v-col cols="8" class="col2-container py-6" v-if="selectedItem || loadingPage || loadingParent"
            :style="{ 'max-height': `calc(100vh - ${heigthColumn}px)` }">
            <div v-if="loadingPage || loadingParent" class="selected-item">
              <ListTitleSkeleton />
            </div>
            <div v-else class="selected-item">
              <div class="content-selected-item py-2">
                <v-icon class="mb-1" size="40" :color="'var(--primary)'">{{
                  actualView.icon
                  }}</v-icon>
                <span v-if="!actualView.isHeaderRow">{{ selectedItem.name }}</span>
                <span v-else>{{ selectedItem.inputFileName }}</span>

              </div>
              <Actions v-if="!actualView.isHeaderRow && cardActions && cardActions.length" :actions="cardActions"
                @action-selected="onActionSelected" />
            </div>

            <div class="table-container" ref="list">
              <DataTableSkeleton v-if="loadingPage || loadingParent" :columnsNumber="1" />
              <v-simple-table v-else fixed-header>
                <template v-if="!actualView.isHeaderRow">
                  <thead>
                    <tr>
                      <th v-if="!isStringType()" class="text-left">
                        {{ traduceItem("key") }}
                      </th>
                      <th class="text-left">{{ traduceItem("value") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, index) in filteredData.values" :key="index">
                      <td v-if="isStringType()">{{ value }}</td>
                      <template v-else>
                        <td>{{ value.key }}</td>
                        <td>{{ value.value }}</td>
                      </template>
                    </tr>
                  </tbody>
                </template>
                <template v-else>
                  <tbody>
                    <tr v-for="(value, key) in data" :key="key">
                      <td class="text-left">{{ traduceItem(key) }}</td>
                      <td v-if="isArrayType(value)" class="pt-3 pb-3">
                        <ul class="d-flex">
                          <li v-for="(val, index) in value" :key="index">
                            <v-chip v-if="key === 'selectedFilterNames'" :class="index !== value.length - 1 && 'mr-3'"
                              style="color: var(--gray); padding: 0 8px;">
                              {{ val }}
                            </v-chip>
                            <span v-else>
                              {{ val }} {{ index !== value.length - 1 ? ', ' : '' }}
                            </span>
                          </li>
                        </ul>
                      </td>
                      <td v-else>
                        <template v-if="key === 'status'">
                          <div class="status">
                            <div class="statusIcon" :class="value">
                              <v-icon v-if="value === 'FINISHED'">
                                mdi-check-circle
                              </v-icon>
                              <v-icon v-else-if="value === 'FAIL'">
                                mdi-alert-circle
                              </v-icon>
                              <v-icon v-else-if="value === 'RUNNING'">
                                mdi-cached
                              </v-icon>
                              <span class="percentage" v-if="value === 'FINISHED'">{{ 100 }}%</span>
                              <span class="percentage" v-else-if="value === 'FAIL'">{{ 0 }}%</span>
                              <span class="percentage" v-else>
                                {{ (filteredData.numberReadPackages * 100) / filteredData.totalNumberPackages }}%
                              </span>
                            </div>
                          </div>
                        </template>
                        <template v-else-if="key === 'user'">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn v-on="on" icon color="var(--fontColorSecondary)"
                                :style="[value.photoUrl ? { backgroundImage: `url(${value.photoUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' } : null]">
                                <v-icon v-if="!value.photoUrl">mdi-account-circle</v-icon>
                              </v-btn>
                            </template>
                            <span v-if="value.name">{{ value.name }}</span>
                            <span v-else>{{ value.email }}</span>
                          </v-tooltip>
                        </template>
                        <template v-else>
                      <td> {{ value }} </td>
                </template>
                </td>
                </tr>
                </tbody>
</template>
</v-simple-table>
</div>
<v-btn v-if="actualView.isHeaderRow" block @click="downloadButton()" :disabled="data.status !== 'FINISHED' || loading"
  :loading="loading" color="--primary" rounded class="mt-4 ml-4 btn-download">
  <v-icon left>mdi-cloud-download</v-icon>
  {{ traduceItem('download') }}
</v-btn>
</v-col>
</div>
</CardContent>
</Card>
</div>
</template>

<script>
import i18n from "@/plugins/i18n";
import { mapActions, mapState } from "vuex";
import { firebaseTools } from "@/mixins/firebase-tools";
import { tools } from "@/mixins/tools";
import editableDataFields from "@/mixins/editable-data-fields";
import ListViewSkeleton from "../skeletonComponents/ListViewSkeleton.vue";
import ListTitleSkeleton from "../skeletonComponents/ListTitleSkeleton.vue";
import DataTableSkeleton from "../skeletonComponents/DataTableSkeleton.vue";

export default {
  name: "ListDetail",
  data: () => ({
    selectedItem: undefined,
    keys: undefined,
    filteredData: [],
    data: {
      status: undefined,
      date: undefined,
      user: {
        email: undefined,
        name: undefined,
        photoUrl: undefined
      },
      startTime: undefined,
      endTime: undefined,
      inputFileName: undefined,
      inputDefinitionName: undefined,
      selectedFilterNames: [],
      outputDefinitionNames: [],
    },
    loading: false,
  }),
  components: {
    ListViewSkeleton,
    ListTitleSkeleton,
    DataTableSkeleton
  },
  props: {
    dataComponent: { type: Array },
    headerHeight: { type: Number },
    loadingParent: { type: Boolean, default: false },
  },
  mixins: [firebaseTools, tools],
  methods: {
    ...mapActions([
      "setItemToConfirmAction",
      "setItemToAddEdit",
      "setItemToSelect"
    ]),
    onActionSelected(event) {
      let copySelectedItem = this.deepCopyFunction(this.selectedItem);
      let fields = this.deepCopyFunction(
        editableDataFields.computed[this.actualView.name]()[event.action]?.fields
      );

      let configEditableDataFields = this.deepCopyFunction(
        editableDataFields.computed[this.actualView.name]()[event.action]?.config
      );

      switch (event.action) {
        case "edit":
          this.setItemToAddEdit({
            section: this.actualView.name,
            fields,
            configEditableDataFields,
            currentDataValues: {
              name: copySelectedItem.name,
              values: copySelectedItem.values
            },
            globalValues: this.deepCopyFunction(this.dataView),
            data: {
              params: {
                collectionName: this.actualView.get[0].collection,
                documentId: copySelectedItem.id,
              },
              method: "updateDocument",
            },
          });
          break;
        case "delete":
          this.setItemToConfirmAction({
            title: i18n.t(`delete${this.actualView.name}`),
            text: i18n.t(`delete${this.actualView.name}Question`),
            data: {
              params: {
                collectionName: this.actualView.get[0].collection,
                documentId: copySelectedItem.id,
                name: copySelectedItem.name
              },
              method: "deleteDocument",
            },
          });
          break;
      }
    },
    setSelectedItem() {
      if (this.itemToSelect) {
        this.selectedItem = this.dataComponent.find((element) => element.id === this.itemToSelect.id)
        this.setItemToSelect(undefined);
      }
      if (this.dataComponent && this.dataComponent.length && !this.selectedItem) {
        this.selectedItem = this.dataComponent[0];
      } else if (
        this.dataComponent &&
        this.dataComponent.length &&
        this.selectedItem
      ) {
        this.selectedItem = this.dataComponent.find(
          (element) => element.id === this.selectedItem.id
        );
        if (!this.selectedItem) this.selectedItem = this.dataComponent[0];
      }
      else this.selectedItem = null;
    },
    setData() {
      this.$refs.list?.children[0].scrollIntoView();
      this.setSelectedItem();
      this.filteredData = this.deepCopyFunction(this.selectedItem);
      if (!this.filteredData) return;
      if (!this.selectedItem || !this.selectedItem.values) {
        for (const element of (this.dataComponent || [])) {
          element.date = this.getFormattedDate(new Date(element.startDate.seconds * 1000))
        }

        this.data.status = this.filteredData.status
        let startDate = new Date(this.filteredData.startDate.seconds * 1000)
        this.data.date = this.getFormattedDateField(startDate)
        this.data.startTime = this.getFormattedTime(startDate)
        this.data.user.name = this.filteredData.user.name
        this.data.user.photoUrl = this.filteredData.user.photoUrl
        this.data.user.email = this.filteredData.user.email
        this.getEndTime()
        this.data.inputFileName = this.filteredData.inputFileName
        this.data.inputDefinitionName = this.filteredData.inputDescriptorInfo ? this.filteredData.inputDescriptorInfo[0].value : "--"
        this.data.outputDefinitionNames = this.filteredData.outputDescriptorInfo ? this.filteredData.outputDescriptorInfo.map(value => value.value) : "--"
        this.data.selectedFilterNames = this.filteredData.selectedFilterNames?.length ? this.filteredData.selectedFilterNames : '--';
      }
    },
    getFormattedDate(date) {
      let dayOfStartDate = String(date.getDate()).padStart(2, '0')
      let monthOfStartDate = String(date.getMonth() + 1).padStart(2, '0') //January is 0!
      let yearOfStartDate = date.getFullYear()
      let startDateFormatted = dayOfStartDate + '/' + monthOfStartDate + '/' + yearOfStartDate

      let today = new Date()
      let dayOfToday = String(today.getDate()).padStart(2, '0')
      let monthOfToday = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
      let yearOfToday = today.getFullYear()
      let todayFormated = dayOfToday + '/' + monthOfToday + '/' + yearOfToday

      if (startDateFormatted == todayFormated)
        return this.getFormattedTime(date)
      else {
        if (yearOfStartDate == yearOfToday) {
          let month = date.toLocaleString('default', { month: 'long' })
          return dayOfStartDate + " " + month.charAt(0).toUpperCase() + month.slice(1)
        } else
          return startDateFormatted
      }
    },
    getFormattedDateField(date) {
      let dayOfStartDate = String(date.getDate()).padStart(2, '0')
      let month = date.toLocaleString('default', { month: 'long' })
      let yearOfStartDate = date.getFullYear()
      return dayOfStartDate + " " + month.charAt(0).toUpperCase() + month.slice(1) + " " + yearOfStartDate
    },
    getEndTime() {
      let endTime = this.filteredData.endDate

      if (endTime === null) {
        this.data.endTime = "--"
      } else {
        let endDate = new Date(endTime.seconds * 1000)
        this.data.endTime = this.getFormattedTime(endDate);
      }
    },
    getFormattedTime(date) {
      return this.checkHour(date.getHours()) + ":" + this.checkHour(date.getMinutes()) + ":" + this.checkHour(date.getSeconds())
    },
    checkHour(hour) {
      return (hour < 10) ? "0" + hour : hour;
    },
    isStringType() {
      if (this.dataView && this.dataView[0].values) return typeof this.dataView[0].values[0] === "string";
    },
    isArrayType(value) {
      return Array.isArray(value);
    },
    downloadButton() {
      let params = new FormData();
      params.append("processId", this.selectedItem.id);
      params.append("inputDescriptorId", this.selectedItem.inputDescriptorInfo[0].key);
      params.append("outputDescriptorIds", this.selectedItem.outputDescriptorInfo ? this.filteredData.outputDescriptorInfo.map(value => value.key) : "")
      params.append("lang", this.userLogged.lang);
      this.loading = true;
      this.downloadFile(
        window.PROJECT_ENV.BASE_URL_READER + "download",
        "post",
        null,
        params
      ).then(() => {
        this.loading = false;
      })
    }
  },
  watch: {
    dataComponent() {
      this.setData();
    },
  },
  mounted() {
    this.setData();
  },

  computed: {
    ...mapState(["dataView", "actualView", "userLogged", "itemToSelect", "loadingPage"]),
    cardActions() {
      return this.setActions(this.actualView.componentActions);
    },
    heigthColumn() {
      return this.headerHeight + 30;
    },
  },
};
</script>
<style lang="scss">
.status {
  color: var(--fontColorTerciary);
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 10px;

  >div {
    display: flex;
    flex-wrap: wrap;

    span {
      line-height: 14px;
    }
  }

  .v-btn {
    border-color: var(--borderGray);
    color: var(--fontColorSecondary);
    text-transform: none;
    font-weight: 400;

    .v-icon {
      font-size: 0.9rem;
    }
  }

  .v-icon {
    padding: 5px;
    font-size: 18px;
  }

  .statusIcon {
    border-radius: 5px !important;
    display: flex;
    align-items: center;
    justify-content: center;

    .percentage {
      font-weight: 700;
      padding-right: 5px;
    }

    &.FAIL,
    &.STOPPED {
      border: 1px solid var(--red);
      color: var(--red);

      .v-icon {
        color: var(--red);
      }
    }

    &.FINISHED {
      border: 1px solid var(--green);
      color: var(--green);

      .v-icon {
        color: var(--green);
      }
    }

    &.WARNING {
      border: 1px solid var(--orange);
      color: var(--orange);

      .v-icon {
        color: var(--orange);
      }
    }

    &.RUNNING {
      border: 1px solid var(--blue);
      color: var(--blue);

      .v-icon {
        color: var(--blue);
        animation: spin-animation 2s linear infinite;
      }
    }
  }

  @keyframes spin-animation {
    0% {
      transform: rotate(360deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }
}

ol,
ul {
  list-style: none;
  padding: 0 !important;
  margin: 0 !important;
}

.card-header-content {
  color: var(--fontColor) !important;

}

.card-content {
  padding-left: 0px !important;
}

.col1-container {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  padding: 0px !important;

  .selectedItem {
    background: rgba(156, 64, 199, 0.2);
    color: var(--primary);

    .icon-color {
      color: var(--primary) !important;
    }
  }

  .list:not(:last-child) {
    margin-bottom: 10px;
  }

  .list {
    cursor: pointer;

    &:hover {
      background: var(--menuGray);
    }
  }
}

.col2-container {
  height: auto;
  display: flex;
  flex-direction: column;
  border-left: 1px solid var(--borderGray);

  .selected-item {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    padding: 10px 10px;
    margin-left: 10px;
    margin-right: -10px;
    border: 1px solid var(--borderGray);
    border-radius: 10px;
    height: auto;
    color: var(--primary);

    .content-selected-item {
      flex-grow: 1;
      font-size: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }
  }

  .table-container {
    height: auto;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: -10px;
    border-radius: 10px;

    tr .text-left {
      color: var(--fontColorTerciary) !important;
      font-weight: 400;
    }

    tr td {
      color: var(--fontColor);
    }

    .v-data-table {
      overflow-x: auto;
      overflow-y: auto;
      display: flex;
      max-height: calc(100vh - 380px);
      border: 1px solid var(--borderGray);
      border-radius: 10px;

      .v-data-table__wrapper {
        width: 100%;
      }
    }
  }

  .btn-download {
    border: 1px solid var(--borderGray);
    color: var(--white) !important;
  }
}
</style>
