<template>
  <div class="container" ref="headerCards">
		<div v-for="(card, index) in cards" :key="index" class="cards-detail">
			<Card class="d-flex px-3 py-3 info-card">
				<CardContent class="card-content d-flex p-0">
          <div class="icon-loader"></div>
          <v-skeleton-loader class="d-flex flex-column text-card-loader">
            <span class="v-skeleton-loader__bone main-text"></span>
            <span class="v-skeleton-loader__bone secondary-text"></span>
          </v-skeleton-loader> 
				</CardContent>
			</Card>
		</div>
	</div>
</template>

<script>
	export default {
		name: "InformationCardsSkeleton",
    data: () => ({
      cards: [1, 2, 3, 4, 5],
    }),
	};
</script>

<style scoped>
	.v-skeleton-loader__bone {
		border-radius: 20px;
	}
  .text-card-loader {
    gap: 6px;
  }
  .main-text {
    height: 16px;
    width: 130px;
    background-color: #9999997e;
  }
  .secondary-text {
    height: 14px;
    width: 90px;
    background-color: #9999994d;

  }
	.container {
		padding: 20px 10px;
		margin: -20px -10px;
		overflow-x: auto;
		display: grid;
		grid-template-columns: repeat(auto-fit, 200px);
		gap: 20px;
	}
  .icon-loader {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #9999997e;
  }
	.info-card {
		width: 200px;
    height: 64px;
		white-space: nowrap;
	}

  .card-content {
    gap: 6px;
  }
</style>