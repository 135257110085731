<template>
	<v-skeleton-loader class="d-flex">
		<span
			class="v-skeleton-loader__bone"
			:style="{ width: multiple ? '97px' : '172px' }"></span>
		<span
			class="v-skeleton-loader__bone"
			:style="{ width: multiple ? '97px' : '172px' }"
			v-if="multiple"></span>
		<span
			class="v-skeleton-loader__bone"
			:style="{ width: multiple ? '97px' : '172px' }"
			v-if="multiple"></span>
	</v-skeleton-loader>
</template>

<script>
	export default {
		name: "ActionsSkeleton",
		props: {
			view: { type: String, default: "ProcessHistory" },
		},
		computed: {
			multiple() {
				return ![
					"ProcessHistory",
					"InputDefinitions",
					"OutputDefinitions",
					"Dictionaries",
					"Lists",
				].includes(this.view);
			},
		},
	};
</script>

<style scoped>
	span {
		height: 36px;
		border-radius: 20px;
		background-color: #9999997e;
		margin-left: 10px;
		width: 100px;
	}
</style>
