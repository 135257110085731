<template>
  <div class="container">
    <div class="icon-loader"></div>
    <v-skeleton-loader class="d-flex flex-column text-card-loader">
      <span class="v-skeleton-loader__bone main-text"></span>
    </v-skeleton-loader> 
	</div>
</template>

<script>
	export default {
		name: "ListTitleSkeleton",
    data: () => ({
      cards: [1, 2, 3, 4, 5],
    }),
	};
</script>

<style scoped>
	.v-skeleton-loader__bone {
		border-radius: 20px;
	}
  .main-text {
    height: 20px;
    width: 191px;
    border-radius: 6px;
    background-color: #9999997e;
  }
	.container {
		overflow-x: auto;
		display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
	}
  .icon-loader {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #9999997e;
  }
</style>