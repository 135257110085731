<template>
  <div>
    <v-form ref="conditionForm" @submit.prevent>
      <h3 class="legend-form">{{ $t("firstTermField") }}</h3>    
      <AssignValues 
        v-bind="$props"
        :config="{ label: $t('assignValueFirstTerm') }"
        :currentDataValues="conditionForm"
        :configSubComponent="{ ...configSubComponent, formAttr: 'initialColumnName' }"
        referenceAttr="initialColumnName"
        :parentComponent="isFilters ? 'ConditionFiltersForm' : 'ConditionForm'"
        @update="updateForm('initialColumnName', $event)"
      />
      <h3 class="legend-form">{{ $t("conditional") }}</h3>   
      <v-autocomplete
        v-model="conditionForm.comparisonOperator"
        :label="configConditionalSelect.label"
        :items="configConditionalSelect.dataSource ?? []"
        :rules="[...configConditionalSelect.validators]"
        :item-text="'name'"
        :item-value="'value'"
        class="conditional-select"
        clearable
        @input="updateForm('comparisonOperator', {'comparisonOperator': $event})"
      ></v-autocomplete>
      <template v-if="isEndValueReferenceShow">
        <h3 class="legend-form">{{ $t("secondTermField") }}</h3>   
        <AssignValues 
          v-bind="$props"
          :config="{label: $t('assignValueSecondTerm')}"
          :currentDataValues="conditionForm"
          :configSubComponent="{ ...configSubComponent, formAttr: 'endValueReference' }"
          referenceAttr="endValueReference"
          :parentComponent="isFilters ? 'ConditionFiltersForm' : 'ConditionForm'"
          @update="updateForm('endValueReference', $event)"
        />
      </template>
    </v-form>
  </div>
</template>

<script>
import { tools } from "@/mixins/tools";
import i18n from "@/plugins/i18n";
import AssignValues from "./AssignValues.vue";

export default {
  name: "ConditionForm",
  mixins: [tools],
  components:{
    AssignValues
  },
  props: {
    globalValues: { type: Array },
    currentDataValues: { type: Object },
    submitFormComponent: { type: Boolean },
    resetForm: { type: Boolean },
    dataSource: { type: Object },
    getStatus: { type: Boolean, default: false },
    formAttribute: { type: String}
  },
  data: () => ({
    conditionForm: {},
    dirty: false,
  }),
  computed: {
    hasChangesForm(){
      return !this.checkAreEqualObjects(this.conditionForm, this.currentDataValues)
    },
    isValid(){
      const {initialColumnName, comparisonOperator , endValueReference} = this.conditionForm;
      const noNeedEnValue = ['EMPTY', 'NOT_EMPTY'].includes(comparisonOperator)
      const checkValidation = !!initialColumnName && !!comparisonOperator && !!(endValueReference || noNeedEnValue )
      return checkValidation
    },
    isEndValueReferenceShow(){
      const noShowEndValueReference = ['EMPTY', 'NOT_EMPTY']
      return this.conditionForm.comparisonOperator && !noShowEndValueReference.includes(this.conditionForm.comparisonOperator)
    },
    configConditionalSelect() {
      return {
          name: "comparisonOperator",
          label: i18n.t("conditional"),
          fieldType: "selectBox",
          dataSource: this.dataSource.filterConditionals,
          validators: [       
            v => Boolean(v) || i18n.t("required")
          ],
        }
    },
    isFilters() {
      return this.formAttribute === 'filters'
    },
    configSubComponent() {
      let config = { requiredValidation: true }
      if (this.isFilters) {
        config.dataSource = 'columnsReferencedForFilters'
      }
      return config
    }
  },
  watch: {
    resetForm(){
      this.$refs.conditionForm.reset()      
    },
    submitFormComponent() {
      this.$refs.conditionForm.validate();
    },
    getStatus(val) {
      if (val) this.$emit("dirty", this.dirty);
    },
    dirty(val) {
      this.$emit("dirty", val);
    },
    currentDataValues: {
     deep:true,
     immediate:true,
     handler(val){
      const { comparisonOperator, initialColumnName, endValueReference} = val
      if((comparisonOperator || initialColumnName || endValueReference ) && this.hasChangesForm){
        this.conditionForm = {...val};
        this.$emit('isValidForm', { valid: this.isValid})
      }
    }},
  },
  methods: {
    updateForm(field, value){
      let newValue = value ?? {[field]: value} 
      let data = {
        ...this.conditionForm,
        ...newValue
      }
      if(!this.isEndValueReferenceShow && data['endValueReference']){
        delete data['endValueReference']
      }
      this.conditionForm = {...data}
      if(!this.hasChangesForm)return
      this.dirty = true;     
      this.$emit('isValidForm', { valid: this.isValid})
      this.$emit('update', this.isValid ? this.conditionForm : null )
    },
  }
};
</script>
<style lang="scss" scoped>
  .legend-form{
    color: #117E8A;
    font-weight: 500;
    font-size: 16px;
    margin: 10px 0 30px 0;
  }
</style>
