<template>
	<v-skeleton-loader class="d-flex">
		<span class="v-skeleton-loader__bone"></span>
	</v-skeleton-loader>
</template>

<script>
	export default {
		name: "ViewNameSkeleton"
	};
</script>

<style scoped>
	span {
		height: 20px;
		border-radius: 3px;
		background-color: #99999950;
		width: 191px;
	}
</style>
